import { getList } from '@/api/app'
import { getAreaList } from '@/api/area'
import { getPlaceList } from '@/api/place'
import { getUserApps } from '@/api/user'
import { fillUrl } from 'common/js/utils'
import { getWebsiteConfing, getDictConfing } from '@/api/sysSet'
import { getTypeNotice } from '@/api/notice'

const state = () => ({
  appList: [], // 产品
  userAppList: [], // 用户拥有的产品
  areaList: [], // 地区
  placeList: [], // 地区
  indexBoardStatistics: {}, // 首页数据统计
  websiteConfing: {}, // 网站信息配置
  noticeTypeTree: [], // 消息类型
  localDict: {}, // 本地字典
})
const getters = {
  appList: (state) => state.appList,
  userAppList: (state) => state.userAppList,
  areaList: (state) => {
    let result = state.areaList || []
    if (result.length === 0) {
      result = LS.get('areaList') || []
    }
    return result
  },
  placeList: (state) => state.placeList,
  indexBoardStatistics: (state) => state.indexBoardStatistics,
  deviceStatus: (state, getters) => {
    const data = [
      { value: getters.indexBoardStatistics?.device?.online, name: '运行中' },
      { value: getters.indexBoardStatistics?.device?.offline, name: '已下线' },
      // { value: 0, name: '故障' },
    ]
    return data
  },
  websiteConfing: (state) => state.websiteConfing,
  noticeTypeTree: (state) => state.noticeTypeTree,
  noticeSubType: (state) => {
    const obj = {}
    state.noticeTypeTree?.map((item) => {
      if (item.msg_sub_type) {
        item.msg_sub_type.map((sub) => {
          const { id, title, content, type } = sub
          obj[id] = {
            id,
            title,
            content,
            type,
          }
        })
      }
    })
    return obj
  },
  localDict: (state) => state.localDict,
}
const mutations = {
  setApp(state, appList) {
    state.appList = appList
  },
  setUserApp(state, userAppList) {
    state.userAppList = userAppList
  },
  setArea(state, areaList) {
    state.areaList = areaList
    LS.set('areaList', areaList)
  },
  setPlace(state, placeList) {
    state.placeList = placeList
  },
  setIndexBoardStatistics(state, indexBoardStatistics) {
    console.log('setIndexBoardStatistics', indexBoardStatistics)
    state.indexBoardStatistics = indexBoardStatistics
  },
  setWebsiteData(state, websiteConfing) {
    console.log('setWebsiteData', websiteConfing)
    state.websiteConfing = websiteConfing
  },
  setNoticeTypeTree(state, noticeTypeTree) {
    state.noticeTypeTree = noticeTypeTree
    SS.set('noticeTypeTree', noticeTypeTree)
  },
  setLocalDict(state, localDict) {
    state.localDict = localDict
    SS.set('localDict', localDict)
  },
}
const actions = {
  async getAppList({ commit }) {
    const result = await getList()
    const list = result.data.map((item) => {
      item.label = item.title
      item.value = item.id
      item.logo = fillUrl(item.logo)
      return item
    })
    commit('setApp', list)
  },

  async getUserAppList({ commit }) {
    const result = await getUserApps()
    const list = result.data.map((item) => {
      item.label = item.title
      item.value = item.id
      item.logo = fillUrl(item.logo)
      return item
    })
    commit('setUserApp', list)
  },

  async getAreaList({ commit }) {
    const areaList = LS.get('areaList') || []
    let result = []
    if (areaList.length) {
      result = areaList
    } else {
      result = await getAreaList()
    }
    commit('setArea', result)
  },

  async getPlaceList({ commit }) {
    const result = await getPlaceList()
    commit('setPlace', result.data || [])
  },

  async getWebsiteData({ commit }) {
    const result = (await getWebsiteConfing()) || {}
    if (Object.keys(result).length) {
      for (const [key, val] of Object.entries(result)) {
        if (val && F.isString(val) && val.includes('/uploads/')) {
          result[key] = fillUrl(val)
        }
      }
    }
    commit('setWebsiteData', result || {})
  },
  // 获取通知消息类型
  async getTypeNoticeList({ commit }) {
    const local = SS.get('noticeTypeTree')
    let result = null
    if (local.length) {
      result = local
    } else {
      result = await getTypeNotice()
      result.map((item) => {
        item.children = item.msg_sub_type
      })
    }
    commit('setNoticeTypeTree', result || [])
  },
  // 获取通用配置文件
  async getLocalDict({ commit }) {
    const local = SS.get('localDict')
    let result = {}
    if (Object.keys(local).length) {
      result = local
    } else {
      result = await getDictConfing()
      setTimeout(() => {
        window.location.reload()
      }, 100)
    }
    commit('setLocalDict', result || [])
  },
}
export default { state, getters, mutations, actions }
