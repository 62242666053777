<!--
 * @Author: WGL
 * @Date: 2022-04-16 11:42:27
 * @LastEditors: WGL
 * @LastEditTime: 2022-09-07 14:49:41
 * @Description: 
-->
<template>
  <div class="rich-text-wrapper">
    <template v-if="editContent">
      <jwt-editor v-model="contentHtml" :upload-url="uploadRichTextUrl" />
      <div class="mt15">
        <el-button type="primary" @click="handleSubmitContent">保存</el-button>
      </div>
    </template>
    <div v-else v-html="contentHtml"></div>
  </div>
</template>

<script>
  import { defineComponent, onBeforeMount, reactive, toRefs } from 'vue'
  import request from '@/utils/request'
  import { uploadRichTextUrl } from '@/config/index'

  // import dayjs from 'dayjs'
  export default defineComponent({
    name: 'RichTextView',
    components: {},
    props: {
      contentId: { type: [String, Number], default: '' },
      content: { type: String, default: '' },
      editContent: { type: Boolean, default: false }, // 是否内容可编辑
    },
    setup(props) {
      const state = reactive({
        contentHtml: '',
      })

      // 获取详情
      const getContentDetail = () => {
        console.log('getContentDetail', props)
        const id = props.contentId
        if (props.content) {
          state.contentHtml = props.content
          return
        }
        if (!id) return
        request({
          url: `/story/detail`,
          method: 'get',
          params: {
            id,
          },
        }).then((res) => {
          console.log('getContentDetail res', res)
          state.contentHtml = res.content
        })
      }

      const handleSubmitContent = () => {
        const id = props.contentId
        request({
          url: `/story/edit`,
          method: 'post',
          data: {
            id,
            content: state.contentHtml,
          },
        })
      }

      onBeforeMount(() => {
        getContentDetail()
      })
      return {
        ...toRefs(state),
        uploadRichTextUrl,
        handleSubmitContent,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .rich-text-wrapper {
    width: 100%;
    :deep() {
      video {
        width: 100% !important;
      }
      blockquote {
        line-height: 1.5;
        margin: 10px 0;
        padding: 10px;
        background-color: #f5f2f0;
        border-left: 8px solid #b4d5ff;
      }
      img {
        max-width: 100%;
      }
    }
  }
</style>
