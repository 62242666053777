/*
 * @Author: WGL
 * @Date: 2021-12-03 19:25:33
 * @LastEditors: WGL
 * @LastEditTime: 2023-10-11 18:18:24
 * @Description:
 */
import en from './en'
import zh from './zh'
import store from '@/store'
import { createI18n } from 'vue-i18n'

import enLocale from 'element-plus/dist/locale/en.mjs'
import zhLocale from 'element-plus/dist/locale/zh-cn.mjs'

const messages = {
  en: {
    ...en,
    ...enLocale,
  },
  zh: {
    ...zh,
    ...zhLocale,
  },
}

function getLanguage() {
  return store.getters['settings/language'] || 'zh'
}

const i18n = createI18n({
  locale: getLanguage(),
  messages,
})

export default i18n
