import { getRequest, postRequest } from './api'

const moduleName = 'msg'
export function getNoticeList(params = {}) {
  const url = `${moduleName}/index`
  return getRequest(url, params)
}

export function addNotice(params = {}) {
  const url = `${moduleName}/add`
  return postRequest(url, params)
}

export function editNotice(params = {}) {
  const url = `${moduleName}/edit`
  return postRequest(url, params)
}

export function detailNotice(params = {}) {
  const url = `${moduleName}/detail`
  return getRequest(url, params)
}

export function deleteNotice(params = {}) {
  const url = `${moduleName}/delete`
  return postRequest(url, params)
}

export function readNotice(params = {}) {
  const url = `${moduleName}/readMsg`
  return postRequest(url, params)
}

export function readAllNotice(params = {}) {
  const url = `${moduleName}/readAllMsg`
  return postRequest(url, params)
}

export function getTypeNotice(params = {}) {
  const url = `${moduleName}/getTypeTree`
  return getRequest(url, params)
}
export function getUnreadTotal(params = {}) {
  const url = `${moduleName}/getUnreadTotal`
  return getRequest(url, params)
}
