<template>
  <div>
    <Vue3videoPlay ref="videoPlayer" v-bind="videoOptions" />
  </div>
</template>
<script>
  import Vue3videoPlay from 'vue3-video-play' // 引入组件
  import 'vue3-video-play/dist/style.css' // 引入css
  import { computed } from 'vue'
  export default {
    name: 'VideoPlayer',
    components: {
      Vue3videoPlay,
    },
    props: {
      width: { type: String, default: '450px' }, //播放器高度
      height: { type: String, default: '450px' }, //播放器高度
      color: { type: String, default: '#409eff' }, //主题色
      title: { type: String, default: '' }, //视频名称
      src: { type: String, default: '' }, //视频源
      muted: { type: Boolean, default: false }, //静音
      webFullScreen: { type: Boolean, default: false },
      autoPlay: { type: Boolean, default: true }, //自动播放
      loop: { type: Boolean, default: false }, //循环播放
      mirror: { type: Boolean, default: false }, //镜像画面
      ligthOff: { type: Boolean, default: false }, //关灯模式
      volume: { type: Number, default: 0.3 }, //默认音量大小
      control: { type: Boolean, default: true }, //是否显示控制器
      type: { type: String, default: 'video/mp4' },
    },

    setup(props) {
      const videoOptions = computed(() => {
        let res = props
        res.src = res.src.replace('http:', 'https:')
        return res
      })
      return {
        videoOptions,
      }
    },
  }
</script>
<style scoped></style>
