import request from '@/utils/request'

export function getList(params = { page: 1, per_page: 100 }) {
  return request({
    url: '/menu/list',
    method: 'get',
    params,
  })
}

export function getUserMenuList(params = { page: 1, per_page: 100 }) {
  return request({
    url: '/menu/menus',
    method: 'get',
    params,
  })
}
