<!--
 * @Author: WGL
 * @Date: 2022-04-28 10:04:30
 * @LastEditors: WGL
 * @LastEditTime: 2024-04-18 14:06:04
 * @Description: 
-->
<template>
  <div class="current-page-module"></div>
</template>

<script>
  import { defineComponent, computed, onBeforeMount } from 'vue'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'

  export default defineComponent({
    name: 'CurrentPageModule',
    props: {},
    emits: ['current-page-module'],
    setup(props, { emit }) {
      const store = useStore()
      const { path: currentPath } = useRoute()
      const userMenusObj = computed(() => store.getters['routes/menusObj'])
      const getCurrentPageModule = () => {
        let result = []
        const currentPageModule = userMenusObj.value[currentPath]
        if (currentPageModule?.boards?.length) {
          result = currentPageModule?.boards?.map((item) => item.code)
          emit('current-page-module', result || [])
        }
      }

      onBeforeMount(() => {
        getCurrentPageModule()
      })
      return {}
    },
  })
</script>

<style lang="scss" scoped></style>
