<template>
  <div id="wrapper" @click="clickboard">
    <div v-if="type === 'layout'" id="operation">
      <el-form label-position="left" label-width="100px" :model="form">
        <el-row>
          <el-col :span="24">
            <el-form-item label="ID">
              <el-input
                v-model="form.id"
                auto-complete="off"
                class="input-width"
                disabled
                type="text"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="top">
              <el-slider
                v-model="form.top"
                auto-complete="off"
                :max="height"
                show-input
                :show-tooltip="false"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="left">
              <el-slider
                v-model="form.left"
                auto-complete="off"
                :max="width"
                show-input
                :show-tooltip="false"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="width">
              <el-slider
                v-model="form.width"
                auto-complete="off"
                :max="width"
                show-input
                :show-tooltip="false"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="height">
              <el-slider
                v-model="form.height"
                auto-complete="off"
                :max="height"
                show-input
                :show-tooltip="false"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="borderStyle">
              <el-select v-model="form.borderStyle">
                <el-option
                  v-for="(item, val) in BORDER_STYPE"
                  :key="val"
                  :label="item"
                  :value="val"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="borderWidth">
              <el-slider v-model="form.borderWidth" auto-complete="off" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="borderColor">
              <el-color-picker
                v-model="form.borderColor"
                auto-complete="off"
                class="input-width"
                :min="0"
                type="text"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="backgroundColor">
              <el-color-picker
                v-model="form.backgroundColor"
                auto-complete="off"
                class="input-width"
                type="text"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="zIndex">
              <el-input
                v-model="form.zIndex"
                auto-complete="off"
                class="input-width"
                type="number"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <slot class="dialog-footer" name="footer">
        <el-button v-show="!isAdd" type="danger" @click="delLayout">
          删 除
        </el-button>
        <!-- <el-button @click="submitLayout" type="primary">确 定</el-button> -->
      </slot>
    </div>

    <div
      id="layout-panel"
      :style="'width:' + pwidth + 'px; height:' + pheight + 'px;'"
    >
      <!--元素属性框-->
      <div v-if="type != 'layout'" id="attrbox" :visible="fillDialogVisible">
        <p style="font-size: 18px; color: #fff; line-height: 45px">节目时长</p>
        <el-form label-width="60px" :model="next">
          <el-form-item label="方法">
            <el-select v-model="next.method" placeholder="方法">
              <el-option label="定时" value="timer" />
              <el-option label="视频结束" value="video" />
              <el-option label="音乐结束" value="audio" />
              <el-option label="定制脚本" value="script" />
            </el-select>
          </el-form-item>
          <el-form-item v-if="next.method == 'timer'" label="秒数">
            <el-input
              v-model="next.seconds"
              auto-complete="off"
              class="input-width"
              style="width: 240px"
              type="text"
            />
          </el-form-item>
          <el-form-item label="脚本">
            <el-input
              v-model="script"
              auto-complete="off"
              class="input-width"
              :disabled="next.method != 'script'"
              style="width: 240px"
              type="textarea"
            />
          </el-form-item>
        </el-form>

        <p
          v-if="bindMediaForm.id"
          style="font-size: 18px; color: #fff; line-height: 45px"
        >
          区块属性设定
        </p>
        <el-form
          v-if="bindMediaForm.id"
          label-width="60px"
          :model="bindMediaForm"
        >
          <el-form-item label="id">
            <el-input
              v-model="bindMediaForm.id"
              auto-complete="off"
              class="input-width"
              disabled
              style="width: 240px"
              type="text"
            />
          </el-form-item>
          <el-form-item label="素材">
            <el-button size="small" type="success" @click="showMediaSelector">
              选择
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <!--元素属性框-->
      <!--屏幕显示模拟-->
      <div id="screen">
        <div
          id="board"
          :style="'width:' + width + 'px; height:' + height + 'px;'"
        >
          <div
            v-for="(item, i) in layout"
            :key="i"
            class="cell"
            :class="
              onboard ? '' : item.id == selectedcell ? 'selected' : 'unselected'
            "
            :style="
              'top:' +
              item.top +
              'px; left:' +
              item.left +
              'px; height:' +
              item.height +
              'px; width:' +
              item.width +
              'px; border-style:' +
              item.borderStyle +
              '; border-width:' +
              item.borderWidth +
              'px; border-color:' +
              item.borderColor +
              '; background-color:' +
              item.backgroundColor +
              '; z-index:' +
              item.zIndex +
              ';'
            "
            @click.stop="edit(item)"
          >
            <div
              v-if="
                type === 'fill' &&
                item.bindMedia !== undefined &&
                item.bindMedia !== null
              "
              class="content"
              :data-layout-id="item.id"
            >
              <img
                v-if="item.bindMedia.type === 'image'"
                :alt="item.bindMedia.title"
                :app-src="item.bindMedia.url"
                :data-media-id="item.bindMedia.id"
                :data-media-src="fillUrl(item.bindMedia.url)"
                :height="item.height"
                :src="fillUrl(item.bindMedia.url)"
                :width="item.width"
              />
              <audio
                v-else-if="item.bindMedia.type === 'audio'"
                :app-src="item.bindMedia.url"
                autoplay
                :data-media-id="item.bindMedia.id"
                :data-media-src="fillUrl(item.bindMedia.url)"
                loop
                :src="fillUrl(item.bindMedia.url)"
              ></audio>
              <video
                v-else-if="item.bindMedia.type === 'video'"
                :app-src="item.bindMedia.url"
                autoplay
                :data-media-id="item.bindMedia.id"
                :data-media-src="fillUrl(item.bindMedia.url)"
                :height="item.height"
                loop
                :src="fillUrl(item.bindMedia.url)"
                :width="item.width"
              ></video>
              <iframe
                v-else-if="item.bindMedia.type === 'html5'"
                :app-src="item.bindMedia.url"
                :data-media-id="item.bindMedia.id"
                :data-media-src="fillUrl(item.bindMedia.url)"
                frameborder="0"
                :height="item.height"
                :src="fillUrl(item.bindMedia.url)"
                :width="item.width"
              ></iframe>
            </div>
          </div>
        </div>
        <!--屏幕显示模拟-->

        <div v-if="type === 'layout'" class="btn-wrapper">
          <el-row type="flex">
            <el-button size="small" type="success" @click="addLayoutLeftHalf">
              左 1/2
            </el-button>
            <el-button size="small" type="success" @click="addLayoutRightHalf">
              右 1/2
            </el-button>

            <el-button size="small" type="success" @click="addLayoutUpHalf">
              上 1/2
            </el-button>
            <el-button size="small" type="success" @click="addLayoutDownHalf">
              下 1/2
            </el-button>
          </el-row>
        </div>
      </div>
    </div>

    <el-dialog
      v-model:visible="dialogVisible"
      center
      :close-on-click-modal="false"
      :modal="false"
      :title="isAdd ? '添加区块' : '修改区块'"
      @close="cancelLayout"
    >
      <el-form label-width="120px" :model="form">
        <el-row>
          <el-col :span="8">
            <el-form-item label="id">
              <el-input
                v-model="form.id"
                auto-complete="off"
                class="input-width"
                disabled
                type="text"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="top">
              <el-slider
                v-model="form.top"
                auto-complete="off"
                :max="height"
                show-input
                :show-tooltip="false"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="left">
              <el-slider
                v-model="form.left"
                auto-complete="off"
                :max="width"
                show-input
                :show-tooltip="false"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="width">
              <el-slider
                v-model="form.width"
                auto-complete="off"
                :max="width"
                show-input
                :show-tooltip="false"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="height">
              <el-slider
                v-model="form.height"
                auto-complete="off"
                :max="height"
                show-input
                :show-tooltip="false"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="borderStyle">
              <el-select v-model="form.borderStyle">
                <el-option
                  v-for="(item, val) in BORDER_STYPE"
                  :key="val"
                  :label="item"
                  :value="val"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="borderWidth">
              <el-slider v-model="form.borderWidth" auto-complete="off" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="borderColor">
              <el-color-picker
                v-model="form.borderColor"
                auto-complete="off"
                class="input-width"
                :min="0"
                type="text"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="backgroundColor">
              <el-color-picker
                v-model="form.backgroundColor"
                auto-complete="off"
                class="input-width"
                type="text"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="zIndex">
              <el-input
                v-model="form.zIndex"
                auto-complete="off"
                class="input-width"
                type="number"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <solt class="dialog-footer" name="footer">
        <el-button @click="cancelLayout">取 消</el-button>
        <el-button v-show="!isAdd" type="danger" @click="delLayout">
          删 除
        </el-button>
        <el-button type="primary" @click="submitLayout">确 定</el-button>
      </solt>
    </el-dialog>
    <media-selector
      :visible="mediaSelectorVisible"
      @cancel="hiddenMediaSelector"
      @submit="mediaSelectorCallback"
    />
  </div>
</template>
<script type="text/ecmascript-6">
  import config from "config/index";
  import { fillUrl } from "common/js/utils";
  import { getMediaList, getMediaDetail } from "api/media";
  import MediaSelector from "@/components/mediaSelector";

  export default {
    components: {
      MediaSelector
    },
    props: {
      height: {
        type: [String, Number],
        default:""
      },
      width: {
        type: [String, Number],
        default:""
      },
      layout: {
        type: Array,
        default:()=>[]
      },
      // next: {
      //   type: Object,
      //   default: ()=>{}
      // },
      data: {
        type: Array,
        default:()=>[]
      },
      type: {
        type: String,
        default:''
      },
    },
    data() {
      return {
        BORDER_STYPE: config.BORDER_STYPE,
        MEDIA_TYPE: config.MEDIA_TYPE,
        fillUrl: fillUrl,
        mediaListApi: getMediaList,
        dialogVisible: false,
        fillDialogVisible: false,
        mediaSelectorVisible: false,
        orignalForm: {},
        isAdd: false,
        form: {},
        bindMediaForm: {},
        pwidth: 1920,
        pheight: 1080,
        selectedcell: 0,
        onboard: true,
        next: {},
      };
    },
    computed: {
      script:function(){
        switch(this.next.method){
          case 'timer':
            return "setInterval(function(){api.sendEvent({name:'n'})},"+this.next.seconds*1000+");";
          case 'video':
            return "$('video').eq(0).bind('ended',function(){api.sendEvent({name:'n'})});";
          case 'audio':
            return "$('audio').eq(0).bind('ended',function(){api.sendEvent({name:'n'})});";
          default:
            return "default";
        }
      }
    },
    watch: {
      width: function () {
        this.init();
      },
      script: function () {
        // eslint-disable-next-line vue/no-mutating-props
        this.next.script = this.script
      },
    },
    created() {
      console.log('触发了board render');
      this.init();
    },
    methods: {
      init() {
        if (this.type === "layout") {
          this.pwidth = parseInt(this.width) + 540;
          this.pheight = parseInt(this.height) + 100;
        } else {
          this.pwidth = parseInt(this.width) + 540;
          this.pheight = parseInt(this.height) + 100;
        };
        console.log(this.next);
      },
      clickboard() {
        console.log('clickboard')
        this.onboard = true;
      },
      edit(item) {
        if (this.type === "layout") {
          this.editLayout(item);
        } else if (this.type === "fill") {
          this.editFill(item);
        }
        this.onboard = false;
        this.selectedcell = item.id;
      },
      editLayout(item) {
        this.isAdd = false;
        // this.dialogVisible = true;
        this.form = item;
        this.form.height = parseInt(item.height);
        this.form.width = parseInt(item.width);
        this.form.top = parseInt(item.top);
        this.form.left = parseInt(item.left);
        this.form.borderWidth = parseInt(item.borderWidth);
        // this.orignalForm = JSON.parse(JSON.stringify(item));
      },
      submitLayout() {
        if (this.isAdd) {
          // eslint-disable-next-line vue/no-mutating-props
          this.layout.push(this.form);
        }
        this.dialogVisible = false;
      },
      cancelLayout() {
        this.dialogVisible = false;
      },
      addLayout() {
        this.isAdd = true;
        this.form = {
          id: this.generateLayoutId(),
          top: 0,
          left: 0,
          height: 100,
          width: 100,
          borderStyle: "solid",
          borderWidth: 2,
          borderColor: "#000",
          backgroundColor: "#fff",
          zIndex: this.getLayoutMaxZIndex(),
        };
        this.dialogVisible = true;
      },
      addLayoutLeftHalf() {
        let layoutInfo = this.defaultLayoutInfo();
        layoutInfo.width = parseInt(this.width / 2);
        layoutInfo.height = this.height;
        // eslint-disable-next-line vue/no-mutating-props
        this.layout.push(layoutInfo);
      },
      addLayoutRightHalf() {
        let layoutInfo = this.defaultLayoutInfo();
        layoutInfo.left = parseInt(this.width / 2);
        layoutInfo.width = parseInt(this.width / 2);
        layoutInfo.height = this.height;
        // eslint-disable-next-line vue/no-mutating-props
        this.layout.push(layoutInfo);
      },
      addLayoutUpHalf() {
        let layoutInfo = this.defaultLayoutInfo();
        layoutInfo.width = this.width;
        layoutInfo.height = parseInt(this.height / 2);
        // eslint-disable-next-line vue/no-mutating-props
        this.layout.push(layoutInfo);
      },
      addLayoutDownHalf() {
        let layoutInfo = this.defaultLayoutInfo();
        layoutInfo.top = parseInt(this.height / 2);
        layoutInfo.width = this.width;
        layoutInfo.height = parseInt(this.height / 2);
        // eslint-disable-next-line vue/no-mutating-props
        this.layout.push(layoutInfo);
      },
      delLayout() {
        console.log(this.layout);
        let id = this.form.id;
        let index = null;
        this.layout.forEach((item, i) => {
          if (item.id === id) {
            index = i;
            return;
          }
        });
        console.log(index);
        // eslint-disable-next-line vue/no-mutating-props
        this.layout.splice(index, 1);
        this.dialogVisible = false;
      },
      getLayoutMaxZIndex() {
        let maxZIndex = 0;
        this.layout.forEach((item) => {
          if (item.zIndex > maxZIndex) {
            maxZIndex = item.zIndex;
          }
        });
        return maxZIndex++;
      },
      findLayoutIndexById(layoutId) {
        let index = null;
        this.layout.forEach((item, i) => {
          if (item.id === layoutId) {
            index = i;
            return;
          }
        });
        console.log(index);
        return index;
      },
      generateLayoutId() {
        return new Date().getTime().toString();
      },
      defaultLayoutInfo() {
        return {
          id: this.generateLayoutId(),
          top: 0,
          left: 0,
          height: 100,
          width: 100,
          borderStyle: "solid",
          borderWidth: 1,
          borderColor: "#000",
          backgroundColor: "#fff",
          zIndex: this.getLayoutMaxZIndex(),
        };
      },
      editFill(item) {
        this.bindMediaForm = {
          id: item.id,
        };
      },
      mediaSelectorCallback: async function (args) {
        let mediaId = args;
        console.log('mediaId', mediaId);
        this.bindMediaForm.media_id = mediaId;
        let layoutId = this.bindMediaForm.id;
        let layoutIndex = this.findLayoutIndexById(layoutId);

        let mediaInfo = await this.getMediaInfo(mediaId);
        // eslint-disable-next-line vue/no-mutating-props
        this.layout[layoutIndex].bindMedia = mediaInfo;
      },
      getMediaInfo: async function (mediaId) {
        let param = {
          id: mediaId,
        };
        var mediaInfo = {};
        console.log(111);
        await getMediaDetail(param).then((data) => {
          mediaInfo = data;
        });
        return mediaInfo;
      },
      showMediaSelector() {
        this.mediaSelectorVisible = true;
      },
      hiddenMediaSelector() {
        this.mediaSelectorVisible = false;
      },
    },
  };
</script>
<style rel="stylesheet/scss" lang="scss">
  #wrapper {
    #attrbox {
      width: 350px;
      height: 700px;
      // background: #333;
      float: left;
    }

    #screen {
      float: left;
      margin: 10px;
      transform: scale(0.8);
      transform-origin: top left;
      border-radius: 10px;
      box-shadow: 5px 5px 5px #aaa;
      overflow: hidden;
    }

    #layout-panel {
      padding: 10px;
      border: #ccc solid 1px;
    }
    .btn-wrapper {
      margin-top: 5px;
      margin-left: 6px;
      margin-bottom: 8px;
    }
    #board {
      position: relative;
      overflow: hidden;
      background-color: #ffffff;
      background-image: linear-gradient(
          90deg,
          rgba(200, 0, 0, 0.15) 10%,
          rgba(0, 0, 0, 0) 10%
        ),
        linear-gradient(rgba(200, 0, 0, 0.15) 10%, rgba(0, 0, 0, 0) 10%);
      background-size: 10px 10px;
      .cell {
        position: absolute;
        display: inline-block;
        cursor: pointer;
        box-sizing: content-box;
        .content {
          overflow: hidden;
        }
      }

      .unselected {
        opacity: 0.4;
      }
    }
    #operation {
      float: left;
      width: 388px;
      margin: 10px;
      padding: 25px 15px;
      border: #ccc solid 0.5px;
    }
  }
</style>
