<template>
  <jwt-select-server
    ref="jwtSelectServerRef"
    v-model="selectModel"
    :fill-url="fillUrl"
    list-api="/ad-tpl/index"
    :request="request"
    v-bind="compnentAttrs"
    @change="handleChange"
    @select="handleSelectChange"
  />
</template>

<script>
  import { defineComponent, onBeforeMount, useAttrs, computed, ref } from 'vue'
  import request from '@/utils/request'
  import { fillUrl } from 'common/js/utils'

  // import dayjs from 'dayjs'
  export default defineComponent({
    name: 'SelectServer',
    components: {},
    props: {},
    emits: ['update:modelValue', 'change', 'select'],
    setup(props, { emit }) {
      const selectModel = computed({
        get: () => props.modelValue || '',
        set: (value) => {
          emit('update:modelValue', value)
        },
      })
      const jwtSelectServerRef = ref(null)
      const compnentAttrs = useAttrs()

      const handleChange = (val) => {
        emit('change', val)
      }
      const handleSelectChange = (val) => {
        emit('select', val)
      }

      const getDataList = () => {
        jwtSelectServerRef.value.getDataList()
      }

      onBeforeMount(() => {})
      return {
        jwtSelectServerRef,
        selectModel,
        compnentAttrs,
        fillUrl,
        request,
        handleChange,
        handleSelectChange,
        getDataList,
      }
    },
  })
</script>

<style lang="scss"></style>
