<template>
  <vab-app />
</template>

<script>
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'App',
  })
</script>
