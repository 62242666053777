<template>
  <el-dialog
    id="device-selector"
    center
    :close-on-click-modal="false"
    title="选择设备"
    @close="cancel"
  >
    <el-container>
      <!-- <el-header>Header</el-header> -->
      <el-main>
        <el-table
          v-loading="loading"
          border
          class="table-container"
          :data="deviceList"
          element-loading-text="努力加载中"
          header-cell-class-name="table-header-bold"
          height="400"
          stripe
          @selection-change="handleSelectionChange"
          @sort-change="changeSort"
        >
          <el-table-column type="selection" width="40" />
          <el-table-column align="center" label="ID" prop="id" width="50" />
          <el-table-column align="center" label="名称" prop="name" />
          <el-table-column align="center" label="编号" prop="sn" />
          <el-table-column align="center" label="地址" prop="address" />
          <el-table-column align="center" label="状态" prop="status" width="80">
            <template #default="scope">
              <span
                :style="scope.row.status ? 'color:greenyellow' : 'color:red'"
              >
                {{ NORMAL_STATUS[scope.row.status] }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="上线"
            prop="is_online"
            width="80"
          >
            <template #default="scope">
              <span
                :style="scope.row.is_online ? 'color:green' : 'color:orange'"
              >
                {{ NORMAL_ONLINE_STATUS[scope.row.is_online] }}
              </span>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
      <el-footer>
        <el-row>
          <el-button type="warning" @click.stop="cancel">关 闭</el-button>
          <el-button type="success" @click.stop="submit">确 定</el-button>
        </el-row>
      </el-footer>
    </el-container>
  </el-dialog>
</template>

<script type="text/ecmascript-6">
  import config from "config/index";
  import {
    getDeviceList as listApi
  } from "api/device";

  export default {
    name:"DeviceSelector",
    props: {
      visible: {
        type: Boolean,
        default: true
      }
    },
    emits: ['submit','cancel'],
    data() {
      return {
        NORMAL_STATUS: config.NORMAL_STATUS,
        NORMAL_ONLINE_STATUS: config.NORMAL_ONLINE_STATUS,
        deviceList: [],
        deviceId: [],
        queryFields: {},
        page: 1,
        perPage: 500,
        sorted: "id",
        order: "desc",
        loading: true,
        noMore: false,
      };
    },
    computed: {
    },
    watch: {
      visible (val) {
        if (val){
          this.init();
        }
      },
    },
    created() {
      this.init();
    },
    methods: {
      init() {
        this.page = 1;
        this.sorted = 'id';
        this.order = 'desc';
        this.queryFields = {};
        this.deviceList = [];
        this.deviceId = [];
        this.getList();
      },
      getList() {
        this.loading = true;
        let params = {
          // title: this.title,
          page: this.page,
          sort: this.sorted,
          order: this.order,
          per_page: this.perPage,
          // status: 20
        };

        if (Object.keys(this.queryFields).length !== 0)
        {
          params = Object.assign(params, this.queryFields);
        }
        listApi(params).then((data) => {
          this.formatDataList(data);
        });
      },
      // 对列表数据处理
      formatDataList(result) {
        this.currentPage = result.current_page;
        this.perPage = result.per_page;
        if (result.data.length > 0)
        {
          result.data.forEach((item) => {
            this.deviceList.push(item);
          });
          this.noMore = false;
        }
        else
        {
          this.noMore = true;
        }
        this.total = result.total;
        this.loading = false;
      },
      submit() {
        // console.log(this.deviceId);
        this.$emit('submit', this.deviceId);
        this.cancel();
      },
      changeSort() {

      },
      handleSelectionChange(rows) {
        this.deviceId = [];
        rows.forEach((row) => {
          this.deviceId.push(row.id);
        });
      },
      load() {
        this.page++;
        this.getList();
      },
      cancel() {
        this.$emit('cancel');
      },
    },
  };
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  #device-selector {
    .el-header,
    .el-footer {
      text-align: center;
    }
  }
</style>
