import request from '@/utils/request'

export function getList(params = { page: 1, per_page: 100 }) {
  return request({
    url: '/role/index',
    method: 'get',
    params,
  })
}

export function getRoleOperateTree(params) {
  return request({
    url: '/role/allMenuOperations',
    method: 'get',
    params,
  })
}

export function addRole(data) {
  return request({
    url: '/role/add',
    method: 'post',
    data,
  })
}

export function editRole(data) {
  return request({
    url: '/role/edit',
    method: 'post',
    data,
  })
}

export function addMerchantsRole(data) {
  return request({
    url: '/role/roleAdd',
    method: 'post',
    data,
  })
}

export function editMerchantsRole(data) {
  return request({
    url: '/role/roleEdit',
    method: 'post',
    data,
  })
}
