/* eslint-disable no-undef */
window.F = {
  // ========================================================================  判断数据类型
  // 判断数据是否是对象 {}
  isObject(obj) {
    return {}.toString.call(obj) === '[object Object]'
  },
  // 判断数据是否是对象{}，且对象长度 >0
  hasObject(obj) {
    return this.isObject(obj) && Object.keys(obj).length > 0
  },
  // 判断数据是否是函数 function
  isFunction(obj) {
    return {}.toString.call(obj) === '[object Function]'
  },
  // 判断数据是否是数组 []
  isArray(obj) {
    return {}.toString.call(obj) === '[object Array]'
  },
  // 判断数据是否是数级 []，且长度>0
  hasArray(obj) {
    return this.isArray(obj) && obj.length > 0
  },
  // 判断数据是否是字符串
  isString(obj) {
    return {}.toString.call(obj) === '[object String]'
  },
  // 判断数据是否是数字
  isNumber(obj) {
    return {}.toString.call(obj) === '[object Number]'
  },
  // 判断数据是否有效
  isValid(obj) {
    // eslint-disable-next-line no-mixed-operators
    return (
      (obj !== undefined && obj !== '' && obj !== null) ||
      obj === 0 ||
      obj === false
    )
  },
  sleep(delay) {
    return new Promise((resolve) => setTimeout(resolve, delay))
  },
  // 判断数据的有效性
  isData(obj) {
    return this.hasArray(obj) || this.hasObject(obj) || this.isValid(obj)
  },
  // ======================================================================== 本地存储配置
  $local(name, data) {
    const sname = name || 'userInfo'
    if (this.isValid(data)) {
      LS.set(sname, data)
    } else {
      const gname = LS.get(sname)
      return gname || false
    }
  },
  $localRemove(name) {
    const sname = name || 'userInfo'
    LS.remove(sname)
    SS.remove(sname)
  },

  // ======================================================================== 本地存储配置
  copy(json) {
    return JSON.parse(JSON.stringify(json))
  },
  $getName(data, str) {
    let aa = ''
    if (F.hasArray(data)) {
      data.map((v) => {
        aa += v[str] + '、'
      })
      aa = aa.substring(0, aa.length - 1)
    }
    return aa
  },
  // ======================================================================== 删除数组中的指定值
  delArrValue(arr, value) {
    arr.forEach((v, i) => {
      if (v === value) {
        arr.splice(i, 1)
      }
    })
    return arr
  },
  // ========================================================================
  setEmpty(data) {
    if (this.hasObject(data)) {
      for (const i in data) {
        if (data[i] == null || data[i] === undefined) {
          data[i] = ''
        }
      }
    }
    return data
  },
  // 将无效数据剔除
  getBody(data) {
    for (var i in data) {
      const v = data[i]
      if (!this.isValid(v)) {
        delete data[i]
      }
    }
    return data
  },
}
/* ====================================== localStorage 存储  ====================================== */
window.LS = {
  // ====================================== 设置存储
  set(key, value) {
    let mv = value
    if (F.isObject(value) || F.isArray(mv)) {
      mv = JSON.stringify(mv)
    }
    localStorage.setItem(key, mv)
  },
  // ====================================== 获取存储
  get(key) {
    const k = localStorage.getItem(key)
    if (F.isValid(k)) {
      return JSON.parse(k)
    } else {
      return false
    }
  },
  // 输出全部信息
  output() {
    console.log(localStorage)
  },
  // ====================================== 列出指定 key
  remove(key) {
    if (F.hasArray(key)) {
      key.map((v) => {
        localStorage.removeItem(v)
      })
    } else {
      localStorage.removeItem(key)
    }
  },
  // ====================================== 清除全部
  clear() {
    localStorage.clear()
  },
}
/* ====================================== sessionStorage 存储  ====================================== */
window.SS = {
  // ====================================== 设置存储
  set(key, value) {
    // const v = this.get(key)
    let mv = value

    // // 如果 v 存在，先取再存
    // if (v) {
    //   if (F.isObject(v)) {
    //     // 如果是对象，则合并对象
    //     mv = Object.assign({}, v, mv)
    //   } else if (F.isArray(v)) {
    //     v.push(mv)
    //     mv = v
    //   } else {
    //     mv = value
    //   }
    // }
    // 如果 v 不存在，直接存
    if (F.isObject(mv) || F.isArray(mv)) {
      mv = JSON.stringify(mv)
    }
    sessionStorage.setItem(key, mv)
  },
  // ====================================== 获取存储
  get(key) {
    const k = sessionStorage.getItem(key)
    if (F.isValid(k)) {
      return JSON.parse(k)
    } else {
      return false
    }
  },
  // 输出全部信息
  output() {
    console.log(sessionStorage)
  },
  // ====================================== 列出指定 key
  remove(key) {
    if (F.hasArray(key)) {
      key.map((v) => {
        sessionStorage.removeItem(v)
      })
    } else {
      sessionStorage.removeItem(key)
    }
  },
  // ====================================== 清除全部
  clear() {
    sessionStorage.clear()
  },
}
