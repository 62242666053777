<template>
  <div class="comment-list-wrapper fv ex ml15">
    <div class="">
      <slot name="title">
        <h2 class="mt20 dl mr20">{{ title }}</h2>
      </slot>
      <el-button
        class="ml20"
        type="primary"
        @click="dialogVisibleContent = true"
      >
        发表评论
      </el-button>
    </div>
    <div class="comment-list ex oy">
      <template v-if="listData.length">
        <div v-for="(item, index) in listData" :key="index" class="bbor ptb15">
          <div class="fx">
            <el-avatar :size="50" :src="item?.user_info?.avatar" />
            <div class="plr10 ex mw0">
              <div class="c1">{{ item?.user_info?.name || '-' }}</div>
              <div class="c6 pt5">{{ item?.create_time || '-' }}</div>
              <div class="w mw0 rich-text-content" v-html="item.content"></div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <el-empty description="暂无评论~" />
      </template>
    </div>
  </div>
  <el-dialog
    v-model="dialogVisibleContent"
    center
    :destroy-on-close="true"
    title="发表评论"
    width="80vw"
  >
    <jwt-editor v-model="commentContent" :upload-url="uploadRichTextUrl" />
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisibleContent = false">取消</el-button>
        <el-button type="primary" @click="handleAddComment">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
  import { defineComponent, onBeforeMount, reactive, toRefs } from 'vue'
  // import dayjs from 'dayjs'
  import { getCommentList, addComment } from '@/api/comment'
  import { uploadRichTextUrl } from '@/config/index'

  export default defineComponent({
    name: 'BaseComment',
    components: {},
    props: {
      title: { type: String, default: '评论人员' },
      refType: { type: [String, Number], default: 'story' },
      refId: { type: [String, Number], default: '' },
    },
    setup(props) {
      const state = reactive({
        commentContent: '',
        listData: [],
        dialogVisibleContent: false,
      })

      // 获取数据详情信息
      const getDataList = () => {
        const params = {
          ref_type: props.refType,
          ref_id: props.refId,
        }
        getCommentList(params).then((res) => {
          console.log('res', res)
          state.listData = res.data || []
        })
      }

      const handleAddComment = () => {
        const params = {
          ref_type: props.refType,
          ref_id: props.refId,
          content: state.commentContent,
        }
        console.log('params', params)
        addComment(params).then((res) => {
          console.log('addComment res', res)
          state.dialogVisibleContent = false
          state.commentContent = ''
          getDataList()
        })
      }

      onBeforeMount(() => {
        getDataList()
      })

      return {
        ...toRefs(state),
        uploadRichTextUrl,
        handleAddComment,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .comment-list-wrapper {
    .comment-list {
      .rich-text-content {
        :deep() {
          video {
            width: 100% !important;
          }
          img {
            max-width: 100%;
          }
        }
      }
    }
  }
</style>
