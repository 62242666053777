import request from '@/utils/request'
import { encryptedData } from '@/utils/encrypt'
import { loginRSA } from '@/config'
import { getRequest } from './api'

export async function getUserApps(params = { page: 1, per_page: 100 }) {
  const url = 'user/myapps'
  return getRequest(url, params)
}

export async function login(data) {
  if (loginRSA) {
    data = await encryptedData(data)
  }
  return request({
    url: '/user/login',
    method: 'post',
    data,
  })
}

export async function socialLogin(data) {
  if (loginRSA) {
    data = await encryptedData(data)
  }
  return request({
    url: '/socialLogin',
    method: 'post',
    data,
  })
}

export function getUserInfo() {
  return request({
    url: '/user/profile',
    method: 'get',
  })
}

export function logout() {
  return request({
    url: '/user/logout',
    method: 'get',
  })
}

// 快捷登录
export function quickLogin(data) {
  return request({
    url: '/user/quickLogin',
    method: 'post',
    data,
  })
}

// 注册
export function register(data) {
  return request({
    url: '/user/register',
    method: 'post',
    data,
  })
}

// 手机验证码
export function getCodePhone(data) {
  const { host: domain } = window.location
  return request({
    url: '/sms/send',
    method: 'post',
    data: { ...data, domain },
  })
}

// 切换团队
export function switchTeam(data) {
  return request({
    url: '/user/switchTeam',
    method: 'post',
    data,
  })
}
// 钱包充值
export function walletRecharge(data) {
  return request({
    url: '/wallet/recharge',
    method: 'post',
    data,
  })
}
