/**
 * @description 导出主题配置，注意事项：此配置下的项修改后需清理浏览器缓存！！！
 */
const localDict = SS.get('localDict')
module.exports = {
  // 订单状态
  ORDER_STATUS: {
    '-10': '已废弃',
    0: '已过期',
    5: '租赁中',
    6: '待授权',
    8: '已授权',
    10: '待支付',
    15: '待完成',
    20: '已支付',
    60: '待转账',
    100: '已完成',
  },
  NORMAL_STATUS: {
    0: '禁用',
    1: '启用',
  },
  SHOW_STATUS: {
    0: '隐藏',
    1: '显示',
  },
  GUIDE_PROGRAM_STATUS: {
    '-10': '已下架',
    0: '未发布',
    10: '发布中',
    20: '已发布',
  },
  DEVICE_PROGRAM_AUDIT_STATUS: {
    '-1': '审核不通过',
    0: '待审核',
    1: '审核通过',
  },
  DEVICE_FETCH_STATUS: {
    '-1': '拉取失败',
    0: '未拉取',
    1: '已拉取',
  },

  DEVICE_PROGRAM_RELEASE_STATUS: {
    '-1': '已下架',
    0: '待发布',
    1: '已发布',
  },

  MEDIA_TYPE: {
    image: '图片',
    video: '视频',
    // authorize: '授权',
    audio: '音频',
    // html5: '网页',
    // txt: '文本',
  },

  NORMAL_ONLINE_STATUS: {
    0: '离线',
    1: '在线',
  },

  NORMAL_ACTIVE_STATUS: {
    0: '未激活',
    1: '已激活',
  },

  NORMAL_PUBLIC_STATUS: {
    0: '私有',
    1: '公开',
  },

  /**
   * 变量配置
   */

  //  题目类型
  QUESTION_TYPE: {
    1: '单选题',
    2: '多选题',
  },
  QUESTION_OPTINS: {
    0: 'A',
    1: 'B',
    2: 'C',
    3: 'D',
    4: 'E',
    5: 'F',
    6: 'G',
    7: 'H',
    8: 'L',
    9: 'I',
  },
  // 充值方式
  RECHARGE_TYPE: {
    1: '微信',
    2: '支付宝',
  },
  // 支付方式
  PAY_TYPE: {
    0: '未知',
    1: '微信',
    2: '支付宝',
    3: '微信支付分',
  },

  // 支付状态
  PAY_STATUS: {
    0: '未支付',
    1: '已支付',
  },
  BORDER_STYPE: {
    none: 'none',
    hidden: 'hidden',
    dotted: 'dotted',
    dashed: 'dashed',
    solid: 'solid',
    double: 'double',
    groove: 'groove',
    ridge: 'ridge',
    inset: 'inset',
    outset: 'outset',
    inherit: 'inherit',
  },
  AUTHORIZE_PLATFORMS: {
    0: '未知',
    1: '微信',
    2: '抖音',
    3: '快手',
  },
  AUTHORIZE_PLATFORM: [
    {
      label: '微信',
      title: '微信',
      authorizeUrl: '/third/weixin/bind/url',
      img: '/assets/sys/wechat.png',
      color: '#2aae67',
      value: 1,
    },
    {
      label: '抖音',
      title: '抖音',
      authorizeUrl: '/third/douyin/bind/url',
      img: '/assets/sys/douyin.png',
      color: '#1c0b1b',
      value: 2,
    },
    {
      label: '快手',
      title: '快手',
      authorizeUrl: '/third/kuaishou/bind/url',
      img: '/assets/sys/kuaishou.png',
      color: '#ff4906',
      value: 3,
    },
  ],

  // 订单类型
  ORDER_TYPE: {
    10: '关注',
    20: '购买',
    30: '分享',
    40: '租赁',
    50: '任务',
    60: '押金',
    70: '分成',
    90: '退款',
  },
  BILL_STATUS: {
    0: '待确定',
    1: '有效',
    '-1': '无效',
  },
  // 账单类型
  BILL_TYPE: {
    10: '推广微信公众号',
    11: '推广抖音号',
    12: '分享视频至抖音',
    13: '推广快手号',
    14: '分享视频至快手',
    20: '销售收入',
    21: '押金收入',
    22: '租赁收入',
    50: '购买支出',
    51: '押金退还',
  },
  //任务类型
  TASK_TYPE: {
    1: '推广微信公众号',
    2: '推广抖音号',
    3: '分享视频至抖音',
    4: '推广快手号',
    5: '分享视频至快手',
    6: '图片广告',
    7: '美葩公众号',
    8: '美葩小程序',
    9: '准啊广告',
    10: '网度广告',
    11: '视频广告',
  },
  //任务状态
  TASK_STATUS: {
    '-1': '支付失败',
    0: '未支付',
    10: '进行中',
    20: '已暂停',
    30: '已完成',
  },
  TASK_GENDER: {
    0: '不限',
    1: '男',
    2: '女',
    3: '保密',
  },
  GENDER: {
    0: '未知',
    1: '男',
    2: '女',
    3: '保密',
  },
  //物料类型
  MATERIAL_TYPE: {
    0: '不限',
    1: '环保袋',
    2: '口罩',
    3: '陪护床',
    4: '漱口水',
    5: '按摩椅',
    6: '液体',
  },
  //物料销售类型
  MATERIAL_SALE_TYPE: {
    1: '出售',
    2: '租赁',
    3: '服务',
  },
  MATERIAL_RECORD_TYPE: {
    1: '库存补充',
    2: '物料出售',
    3: '租赁借出',
    4: '租赁归还',
    5: '库存扣除',
  },
  APP_OPERATING_MODEL: {
    0: '公众号推送',
    1: '直链页面',
  },
  FEEDBACK_STATUS: {
    0: '待处理',
    10: '处理中',
    100: '已处理',
  },
  // 策略类型
  POLICY_TYPE: {
    1: '计时',
    2: '包夜',
  },
  TIME_UNIT: {
    minute: '分钟',
    hour: '小时',
    day: '天',
  },
  TASK_LIMIT_CYCLE_UNIT: {
    hour: '时',
    day: '天',
    month: '月',
    year: '年',
  },
  // 扩展属性配置的页面
  EXTEND_ATTR_PAGE: {
    place: '地点',
    device: '设备',
    order: '订单',
  },
  // 扩展属性类型
  EXTEND_ATTR_TYPE: {
    text: {
      title: '文本框',
      type: 'input',
      option: false,
    },
    textarea: {
      title: '多行文本框',
      type: 'input',
      option: false,
    },
    inputNumber: {
      title: '数字文本框',
      type: 'inputNumber',
      option: false,
    },
    datePicker: {
      title: '日期选择',
      type: 'datePicker',
      option: false,
    },
    timePicker: {
      title: '时间选择',
      type: 'timePicker',
      option: false,
    },
    switch: {
      title: '状态选择',
      type: 'switch',
      option: false,
    },
    radio: {
      title: '单选框',
      type: 'radio',
      option: true,
    },
    checkbox: {
      title: '多选框',
      type: 'checkbox',
      option: true,
      multiple: true,
    },
    select: {
      title: '下拉单选择',
      type: 'select',
      multiple: false,
      option: true,
    },
    selectMultiple: {
      title: '下拉多选择',
      type: 'select',
      multiple: true,
      option: true,
    },
    avatar: {
      title: '单图片',
      type: 'uploadImg',
      multiple: false,
      option: false,
      isImg: true,
    },
    avatarMultiple: {
      title: '多图片',
      type: 'uploadImg',
      multiple: true,
      option: false,
      isImg: true,
    },
  },
  //经销商户类型
  MERCHANT_TYPE: {
    1: '微信支付商户',
    2: '微信个人账户',
    3: '支付宝商户',
    4: '支付宝个人',
    5: '对公银行卡',
    6: '个人银行卡',
  },
  PROFIT_SHARE_TYPE: {
    1: '比例分账',
    2: '固定分账',
  },
  DEVICE_AISLE_EXCEPTION_STATUS: {
    0: '正常',
    1: '反复读卡',
    2: '逻辑有实际无',
    3: '逻辑无实际有',
  },
  // 房间家具
  ROOM_FURNITURE: {
    1: '空调',
    2: 'WIFI',
    3: '消毒柜/茶杯',
    4: '网络电视',
    5: '沙发茶几',
    6: '售卖机',
    7: '麻将机',
  },
  NOTICE_STATUS: {
    0: '未读',
    1: '已读',
  },
  ...localDict,
}
