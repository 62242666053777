<template>
  <jwt-image :fill-url="fillUrl" :fit="fit" :src="src" :type="type" />
</template>

<script>
  import { defineComponent } from 'vue'
  import { fillUrl } from '@/common/js/utils'

  export default defineComponent({
    name: 'BaseImage',
    props: {
      fit: {
        type: String,
        default: 'cover',
      },
      src: {
        type: String,
        default: '',
      },
      type: {
        type: String,
        default: 'search',
      },
    },
    emits: ['table-action'],
    setup() {
      return {
        fillUrl,
      }
    },
  })
</script>

<style lang="scss" scoped></style>
