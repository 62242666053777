export function setupComponents(app) {
  // 加载组件
  const Components = require.context('.', true, /\.vue$/)
  Components.keys()
    .map(Components)
    .forEach((item) => {
      // console.log(`item`, item)
      if (item.default.name) app.component(item.default.name, item.default)
    })
}
