import { getRequest, postRequest } from './api'

const moduleName = 'app'
export function getList(params = { page: 1, per_page: 100 }) {
  const url = `${moduleName}/index`
  return getRequest(url, params)
}

export function install(params) {
  const url = `${moduleName}/install`
  return postRequest(url, params)
}

export function uninstall(params) {
  const url = `${moduleName}/uninstall`
  return postRequest(url, params)
}

// 切换产品
export function changeApp(params) {
  const url = `${moduleName}/enter`
  return postRequest(url, params)
}
