<template>
  <el-dialog
    id="media-selector"
    v-model="dialogVisable"
    center
    :close-on-click-modal="false"
    title="素材选择框"
    width="52%"
    @close="cancel"
  >
    <el-container>
      <el-aside width="120px">
        <el-tabs
          v-model="mediaType"
          style="padding-left: 50px; margin-top: 60px"
          tab-position="left"
        >
          <el-tab-pane
            v-for="(item, type) in MEDIA_TYPE"
            :key="type"
            :data-media-type="type"
            :label="item"
            :name="type"
          />
        </el-tabs>
      </el-aside>
      <el-container>
        <el-header>
          <el-row>
            <el-col>
              <el-input v-model="mediaTitle" placeholder="资源名称" />
            </el-col>
          </el-row>
        </el-header>
        <el-main
          v-infinite-scroll="load"
          class="media-list"
          :infinite-scroll-disabled="noMore"
          style="
            height: 400px;
            overflow: scroll;
            border: solid 1px rgb(116, 116, 116);
            color: #fff;
            font-weight: 800;
            text-align: center;
          "
        >
          <el-row :gutter="24" justify="left" type="flex">
            <el-radio-group v-model="mediaId">
              <el-col
                v-for="item in mediaList"
                :key="item.id"
                class="media-item"
                :lg="6"
                :md="6"
                :sm="12"
                style="height: 168px"
                :xl="24"
                :xs="24"
              >
                <el-radio-button class="media-radio-button" :label="item.id">
                  <el-card :body-style="{ padding: '0px' }">
                    <el-image
                      v-if="item.type === 'image'"
                      fit="fill"
                      lazy
                      :src="fillUrl(item.url, 133, 133)"
                      style="width: 133px; height: 133px"
                    />
                    <audio
                      v-else-if="item.type === 'audio'"
                      controls
                      :height="133"
                      :src="fillUrl(item.url)"
                      :width="133"
                    ></audio>
                    <video
                      v-else-if="item.type === 'video'"
                      controls
                      :height="133"
                      :src="fillUrl(item.url)"
                      :width="133"
                    ></video>
                    <div
                      class="media-item-title"
                      style="width: 120px; overflow: hidden"
                    >
                      <span>
                        {{ item.title }}
                      </span>
                    </div>
                  </el-card>
                </el-radio-button>
              </el-col>
            </el-radio-group>
          </el-row>
          <el-row v-if="loading"><el-col :span="24">加载中...</el-col></el-row>
          <el-row v-if="noMore"><el-col :span="24">没有更多了</el-col></el-row>
        </el-main>
        <el-footer>
          <el-row>
            <el-button type="warning" @click.stop="cancel">关 闭</el-button>
            <el-button type="success" @click.stop="submit">确 定</el-button>
          </el-row>
        </el-footer>
      </el-container>
    </el-container>
  </el-dialog>
</template>

<script type="text/ecmascript-6">
  import { MEDIA_TYPE } from '@/config/variable.config'
  import { fillUrl } from 'common/js/utils'
  import { getMediaList } from 'api/media'

  export default {
    components: {},
    props: {
      visible: {
        type: Boolean,
        default: true,
      },
    },
    emits: ['submit', 'cancel'],
    data() {
      return {
        MEDIA_TYPE: MEDIA_TYPE,
        fillUrl: fillUrl,
        mediaList: [],
        mediaId: null,
        mediaType: 'image',
        mediaTitle: '',
        queryFields: {
          type: 'image',
          title: '',
        },
        page: 1,
        perPage: 15,
        sorted: 'id',
        order: 'desc',
        loading: true,
        noMore: false,
      }
    },
    computed: {
      dialogVisable() {
        return this.visible
      },
    },
    watch: {
      mediaType() {
        this.queryFields['type'] = this.mediaType
        this.init()
      },
      mediaTitle() {
        this.queryFields['title'] = this.mediaTitle
        this.init()
      },
    },
    created() {
      this.init()
    },
    methods: {
      init() {
        this.page = 1
        this.mediaList = []
        this.noMore = false
        this.getList()
      },
      getList() {
        this.loading = true
        let params = {
          page: this.page,
          sort: this.sorted,
          order: this.order,
          per_page: this.perPage,
          // status: 20
        }

        if (this.queryFields) {
          params = Object.assign(params, this.queryFields)
        }

        getMediaList(params).then((data) => {
          this.formatDataList(data)
          this.loading = false
        })
      },
      formatDataList(data) {
        console.log('data', data)
        let result = data
        this.currentPage = result.current_page
        this.perPage = result.per_page
        if (result.data.length > 0) {
          result.data.forEach((item) => {
            this.mediaList.push(item)
          })
          this.noMore = false
        } else {
          this.noMore = true
        }
        // this.mediaList = result.data;
        this.total = result.total
        this.loading = false
      },
      submit() {
        let mediaInfo = null
        this.mediaList.forEach((item) => {
          if (item.id === this.mediaId) {
            mediaInfo = item
          }
        })
        this.$emit('submit', this.mediaId, mediaInfo)
        this.cancel()
      },
      cancel() {
        this.$emit('cancel')
      },
      getInputVal(type, args) {
        console.log('type', type)
        console.log('args', args)
        this.queryFields[type] = args[0]
        this.init()
      },
      load() {
        this.page++
        this.getList()
      },
      mediaTypeChange(tab) {
        let mediaType = tab.$attrs['data-media-type']
        this.queryFields['type'] = mediaType
        this.init()
      },
    },
  }
</script>

<style lang="scss" scoped>
  #media-selector {
    .el-header,
    .el-footer {
      color: #333;
      text-align: center;
      line-height: 60px;
    }

    .el-aside {
      color: #333;
      text-align: center;
      line-height: 200px;
    }

    .media-list {
      height: 400px;
      overflow: scroll;
      border: solid 1px rgb(116, 116, 116);
      background-color: #1e252c;
      color: #fff;
      font-weight: 800;
      text-align: center;
    }

    body > .el-container {
      margin-bottom: 40px;
    }

    .el-container:nth-child(5) .el-aside,
    .el-container:nth-child(6) .el-aside {
      line-height: 260px;
    }

    .el-container:nth-child(7) .el-aside {
      line-height: 320px;
    }
    .button {
      padding: 0;
      float: right;
    }

    .image {
      width: 133px;
      height: 133px;
      display: block;
    }

    .clearfix:before,
    .clearfix:after {
      content: '';
    }

    .clearfix:after {
      clear: both;
    }

    .el-radio-button:first-child:last-child .el-radio-button__inner {
      padding: 3px !important;
    }
  }
</style>
