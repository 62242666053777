<template>
  <div class="avatar-wrapper">
    <el-upload
      :action="uploadUrl"
      :before-upload="beforeUpload"
      :class="{ disabled: uploadDisabled }"
      :data="extendParam"
      :disabled="isDisabled"
      :file-list="imageList"
      :headers="headers"
      :list-type="computedListType"
      name="filedata"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
      :on-success="handleUploadSuccess"
      :show-file-list="isShowFileList"
      :with-credentials="true"
    >
      <i v-show="!showBtn" class="el-icon-plus"></i>
      <div class="btn-wrapper">
        <el-button v-show="showBtn" type="primary">上传</el-button>
      </div>
    </el-upload>
    <el-dialog v-model="dialogVisible" :z-index="10000">
      <img :src="dialogImageUrl" style="height: 100%; width: 100%" />
    </el-dialog>
  </div>
</template>
<script type="text/ecmascript-6">
  import config from "config/index";
  import store from '@/store'
  import { checkUrl } from "@/common/js/utils";
  export default {
    name:"UploadImage",
    props: {
      // eslint-disable-next-line vue/require-default-prop
      imgPath: {
        type: String,
        default: "",
      },
      // eslint-disable-next-line vue/require-default-prop
      imgPathArr: {
        type: [Array,String],
        default: ()=>[]
      },
      limitSize: {
        // 限制默认上传图片大小以KB为单位(默认为2M)
        type: String,
        default: "2048"
      },
      isList: {
        type: Boolean,
        default: false
      },
      // eslint-disable-next-line vue/require-default-prop
      listType: {
        type: String,
        default: "picture-card"
      },
      isBtn: {
        // 是否显示Btn
        type: Boolean,
        default: false
      },
      // eslint-disable-next-line vue/require-default-prop
      uploadUrl: {
        type: String,
        default: config.uploadUrl
      },
      // eslint-disable-next-line vue/require-default-prop
      uploadType: {
        // 上传类型
        type: String,
        default: "img"
      },
      isDisabled: {
        // 是否禁用
        type: Boolean,
        default: false
      },
    },
    emits: ['callback'],
    data() {
      return {
        imageList: [],
        imageArr: [],
        dialogImageUrl: "",
        dialogVisible: false,
        isShowFileList: false,
        extendParam: {
          'type': 'image'
        }
      };
    },
    computed: {
      uploadDisabled() {
        if (!this.isList) {
          return this.imageList.length > 0;
        }
        return false
      },
      computedListType() {
        return this.listType ? this.listType : "picture-card";
      },
      showBtn() {
        if (this.isBtn) {
          return true;
        }
        return false;
      },
      headers() {
        return {
          Authorization: store.getters['user/token']
        }
      },
    },
    watch: {
      imgPath: function () {
        console.log('watch imgPath', )
        this.init();
      }
    },
    created() {
      this.init();
    },
    methods: {
      init() {
      console.log('init imgPath', )
        if (this.imgPath) {
          const url = checkUrl(this.imgPath);
          this.imageList = [{ url }];
          this.isShowFileList = true;
          this.imageArr.push(url);
        } else if (this.imgPathArr && this.imgPathArr.length > 0) {
          this.imgPathArr.map((item) => {
            const obj = { url: "" };
            obj["url"] = checkUrl(item);
            this.imageList.push(obj);
          });
        } else {
          this.imageList = [];
          this.imageArr = [];
        }
      },
      // 上传之前
      beforeUpload(file) {
        let allowType = true;
        const fileType = file.type;
        console.log("fileType", file);
        const limitSize = parseInt(this.limitSize);
        const size = limitSize / 1024;
        const isLt2M = file.size / 1024 < limitSize;
        allowType = fileType === "image/jpeg" || "image/png" || "image/gif" || "image/webp";
        if (!allowType) {
          this.$message.error("上传图片只能是 JPG,PNG,GIF,WEBP 格式!");
        }
        if (!isLt2M) {
          if (size > 1) {
            this.$message.error(`上传图片大小不能超过 ${size}MB!`);
          } else {
            this.$message.error(`上传图片大小不能超过 ${limitSize}KB!`);
          }
        }
        return allowType && isLt2M;
      },
      // 上传成功
      handleUploadSuccess(res, file, fileList) {
        if (res.code === 0) {
          const httpPath = res.data.http_path;
          this.imageArr.push(httpPath);
          console.log('handleUploadSuccess imgPath',res.data)
          this.$emit("callback", res.data);

          this.imageList = fileList;
          this.isShowFileList = true;
        } else {
          this.$message.error(res.msg);
          this.dialogVisible = false;
        }
      },
      // 文件列表移除文件时的钩子
      handleRemove(file, fileList) {
        this.imageList = fileList;
        let imageArr = [];
        fileList.length > 0 &&
          fileList.map((item) => {
            const url = item.response.data[0].savename;
            imageArr.push(url);
          });
        if (fileList.length === 0) {
          imageArr = "";
        }
        this.$emit("callback", {});
      },
      // 点击文件列表中已上传的文件时的钩子
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      }
    }
  };
</script>
<style rel="stylesheet/scss" lang="scss">
  .avatar-wrapper {
    width: 100%;
    text-align: left;
    .disabled {
      background-color: transparent;
      .el-upload--picture-card {
        display: none;
      }
    }
  }
  .btn-wrapper {
    display: flex;
    audio {
      height: 40px;
      margin-right: 10px;
    }
  }
</style>
