import { getRequest, postRequest } from './api'

const moduleName = 'device'
export function getDeviceList(params = {}) {
  const url = `${moduleName}/index`
  return getRequest(url, params)
}

export function addDevice(params = {}) {
  const url = `${moduleName}/add`
  return postRequest(url, params)
}

export function editDevice(params = {}) {
  const url = `${moduleName}/edit`
  return postRequest(url, params)
}

export function getDeviceDetail(params = {}) {
  const url = `${moduleName}/detail`
  return getRequest(url, params)
}

export function deleteDevice(params = {}) {
  const url = `${moduleName}/delete`
  return postRequest(url, params)
}

// 激活设备
export function activeDevice(params = {}) {
  const url = `${moduleName}/active`
  return postRequest(url, params)
}

export function sendCommand(params = {}) {
  const url = `${moduleName}/sendCommand`
  return postRequest(url, params)
}
