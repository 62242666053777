<template>
  <div class="avatar-wrapper">
    <el-upload
      :action="uploadAction"
      :before-upload="beforeUpload"
      class="upload-audio el-upload--picture-card"
      :data="extendParam"
      :disabled="isDisabled"
      :file-list="fileList"
      :headers="headers"
      name="filedata"
      :on-remove="handleRemove"
      :on-success="handleUploadSuccess"
      :show-file-list="isShowFileList"
      :with-credentials="true"
    >
      <vab-icon
        v-show="!showBtn && !mediaUrl"
        icon="add-line"
        :style="{
          fontSize: 40 + 'px',
        }"
      />
      <div class="btn-wrapper">
        <audio
          v-show="uploadType === 'audio' && mediaUrl"
          id="video1"
          controls="controls"
          :src="mediaUrl"
        >
          Your browser does not support the audio element.
        </audio>
        <video
          v-show="uploadType === 'video' && mediaUrl"
          controls="controls"
          :src="mediaUrl"
        >
          您的浏览器不支持 video 标签。
        </video>
        <el-button v-show="showBtn" type="primary">上传</el-button>
      </div>
    </el-upload>
  </div>
</template>
<script type="text/ecmascript-6">
    import config from "config/index";
    import { checkUrl } from "common/js/utils";
    import store from '@/store'
    export default {
      name:'UploadAudio',
      props: {
        // eslint-disable-next-line vue/require-default-prop
        imgPath: {
          type: String,
          defalut: ""
        },
        limitSize: {
          // 限制默认上传图片大小以KB为单位(默认为2M)
          type: String,
          default: "2048"
        },
        isBtn: {
          // 是否显示Btn
          type: Boolean,
          default: false
        },
        // eslint-disable-next-line vue/require-default-prop
        uploadUrl: {
          type: String,
          defalut: config.uploadMediaUrl
        },
        // eslint-disable-next-line vue/require-default-prop
        uploadType: {
          // 上传类型
          type: String,
          defalut: "audio"
        },
        isDisabled: {
          // 是否禁用
          type: Boolean,
          defalut: false
        }
      },
  emits: ['callback'],
      data() {
        return {
          fileList: [],
          mediaUrl: '',
          dialogImageUrl: "",
          dialogVisible: false,
          isShowFileList: false
        };
      },
      computed: {
        uploadAction() {
          return config.uploadMediaUrl;
        },
        showBtn() {
          if (this.isBtn) {
            return true;
          }
          return false;
        },
        extendParam() {
          if (this.uploadType) {
            return {
               type: this.uploadType
            };
          }
          return {
            type: 'audio'
          };
        },
        headers() {
          return {
            Authorization: store.getters['user/token'],
          }
        },
      },
      watch: {
        imgPath: function () {
          this.init();
        }
      },
      created() {
        this.init();
      },
      methods: {
        init() {
          if (this.imgPath) {
            const url = checkUrl(this.imgPath);
            this.fileList = [{ url }];
            this.isShowFileList = true;
            this.mediaUrl = url;
          } else {
            this.fileList = [];
            this.mediaUrl = '';
          }
        },
        // 上传之前
        beforeUpload(file) {
          let allowType = true;
          const fileType = file.type;
          console.log("fileType", file);
          const limitSize = parseInt(this.limitSize);
          const size = limitSize / 1024;
          const isLt2M = file.size / 1024 < limitSize;
          allowType = fileType === "image/jpeg" || "image/png" || "image/gif";
          if (!allowType) {
            this.$message.error("上传图片只能是 JPG,PNG,GIF 格式!");
          }
          if (!isLt2M) {
            if (size > 1) {
              this.$message.error(`上传图片大小不能超过 ${size}MB!`);
            } else {
              this.$message.error(`上传图片大小不能超过 ${limitSize}KB!`);
            }
          }
          return allowType && isLt2M;
        },
        // 上传成功
        handleUploadSuccess(res, file, fileList) {
          console.log(`fileList`, fileList);
          if (res.code === 0) {
            const httpPath = res.data.http_path;
            this.mediaUrl = httpPath;
            this.$emit("callback", res.data);
            this.fileList = fileList.slice(-1);
            this.isShowFileList = true;
          } else {
            this.$message.error(res.msg);
            this.dialogVisible = false;
          }
        },
        // 文件列表移除文件时的钩子
        handleRemove(file, fileList) {
          this.fileList = fileList;
          this.mediaUrl = '';
          // let mediaUrl = '';
          fileList.length > 0 &&
            fileList.map(() => {
              // const url = item.response.data[0].savename;
              // mediaUrl = url;
            });
          if (fileList.length === 0) {
            // mediaUrl = "";
          }
          this.$emit("callback", {});
        }
      }
    };
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
  .avatar-wrapper {
    width: 100%;
    text-align: left;
    .upload-audio {
      min-width: 148px !important;
      min-height: 148px !important;
      width: auto !important;
      height: auto !important;
    }
    .disabled {
      .el-upload--picture-card {
        display: none;
      }
    }
    :deep() {
      .el-upload-list {
        display: none;
      }
    }
    .el-upload--text {
      width: 100% !important;
      height: 100%;
      box-sizing: border-box;
    }
  }

  .btn-wrapper {
    display: flex;
    audio {
      height: 40px;
      margin-right: 10px;
    }
    video {
      max-height: 400px;
    }
  }
</style>
