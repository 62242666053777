import { getRequest, postRequest } from './api'

export function getMediaList(params = {}) {
  const url = 'media/index'
  return getRequest(url, params)
}

export function getMediaDetail(params = {}) {
  const url = 'media/detail'
  return getRequest(url, params)
}

export function addMedia(params = {}) {
  const url = 'media/add'
  return postRequest(url, params)
}

export function editMedia(params = {}) {
  const url = 'media/edit'
  return postRequest(url, params)
}

export function deleteMedia(params = {}) {
  const url = 'media/delete'
  return postRequest(url, params)
}
