<!--
 * @Author: WGL
 * @Date: 2021-11-29 20:29:59
 * @LastEditors: WGL
 * @LastEditTime: 2021-12-03 00:21:09
 * @Description:
-->
<template>
  <el-popover
    ref="popper"
    :append-to-body="true"
    :fallback-placements="['bottom-start', 'top-start', 'right', 'left']"
    :gpu-acceleration="false"
    placement="bottom-start"
    popper-class="is-pure el-cascader__dropdown"
    :popper-options="popperOptions"
    pure
    :stop-popper-mouse-event="false"
    transition="el-zoom-in-top"
    trigger="click"
    :width="popoverWidth"
    @hide="popperVisible = false"
    @show="popperVisible = true"
  >
    <template #reference>
      <div
        class="fxmj select-wrapper"
        :class="[popperVisible && 'select-wrapper-active']"
      >
        <div class="omit plr10 ex" :class="[!selectText && 'c4']">
          {{ selectText ? selectText : '请选择' }}
        </div>
        <i
          key="arrow-down"
          :class="[
            'el-input__icon',
            'el-icon-arrow-down',
            popperVisible && 'reverse-icon',
          ]"
        ></i>
      </div>
      <!-- <el-input v-model="selectText" placeholder="请选择">
        <template #suffix></template>
      </el-input> -->
    </template>
    <template #default>
      <div class="fx area-dropdown-wraper">
        <!-- 省 -->
        <el-scrollbar :height="240">
          <ul class="scrollbar-item">
            <li
              v-for="(item, index) in areaList"
              :key="index"
              class="fxmj"
              :class="{
                'is-active-node': provinceIndex === index || item.checked === 2,
              }"
              @click="handleProvice(item, index)"
            >
              <label
                class="el-checkbox el-checkbox--medium"
                @click.stop="handleProvice(item, index, 'checked')"
              >
                <span
                  class="el-checkbox__input"
                  :class="{
                    'is-indeterminate': item.checked === 1,
                    'is-checked': item.checked === 2,
                  }"
                >
                  <span class="el-checkbox__inner"></span>
                </span>
              </label>
              <span class="el-cascader-node__label ex">
                {{ item.name }}
              </span>
              <i class="el-icon-arrow-right"></i>
            </li>
          </ul>
        </el-scrollbar>
        <!-- 市 -->
        <el-scrollbar v-if="provinceIndex !== ''" :height="240">
          <ul class="scrollbar-item">
            <li
              v-for="(item, index) in cityList"
              :key="index"
              class="fxmj"
              :class="{ 'is-active-node': cityIndex === index }"
              @click="handleCity(item, index)"
            >
              <label
                class="el-checkbox el-checkbox--medium"
                @click.stop="handleCity(item, index, 'checked')"
              >
                <span
                  class="el-checkbox__input"
                  :class="{
                    'is-indeterminate': item.checked === 1,
                    'is-checked': item.checked === 2,
                  }"
                >
                  <span class="el-checkbox__inner"></span>
                </span>
              </label>
              <span class="el-cascader-node__label ex">
                {{ item.name }}
              </span>
              <i class="el-icon-arrow-right"></i>
            </li>
          </ul>
        </el-scrollbar>
        <!-- 区 -->
        <el-scrollbar v-if="cityIndex !== ''" :height="240">
          <ul class="scrollbar-item">
            <li
              v-for="(item, index) in countyList"
              :key="index"
              class="fxmj"
              @click="handleCounty(item, index)"
            >
              <label
                class="el-checkbox el-checkbox--medium"
                @click.stop="handleCounty(item, index, 'checked')"
              >
                <span
                  class="el-checkbox__input"
                  :class="{
                    'is-indeterminate': item.checked === 1,
                    'is-checked': item.checked === 2,
                  }"
                >
                  <span class="el-checkbox__inner"></span>
                </span>
              </label>
              <span class="el-cascader-node__label ex">
                {{ item.name }}
              </span>
              <i class="el-icon-arrow-right"></i>
            </li>
          </ul>
        </el-scrollbar>
      </div>
    </template>
  </el-popover>
</template>

<script>
  import { defineComponent, reactive, toRefs, computed, watch } from 'vue'
  import { useStore } from 'vuex'

  export default defineComponent({
    name: 'CascaderArea',
    props: {
      defaultVal: {
        type: String,
        default: '',
      },
    },
    emits: ['table-action', 'onChange'],
    setup(props, { emit }) {
      watch(props, (newValue) => {
        if (newValue.defaultVal) {
          initData(newValue.defaultVal)
        }
      })
      const store = useStore()
      const areaList = computed(() => store.getters['sys/areaList'])

      const areaListObj = computed(() => {
        const obj = {}
        areaList.value.forEach((item) => {
          const { id } = item
          obj[id] = item
          if (item?.children?.length) {
            item.children.forEach((v) => {
              const { id: vid } = v
              obj[vid] = v
              if (v?.children?.length) {
                v.children.forEach((vv) => {
                  const { id: vvid } = vv
                  obj[vvid] = vv
                })
              }
            })
          }
        })
        return obj
      })

      console.log(`areaListObj`, areaListObj)
      const state = reactive({
        popperVisible: false,
        provinceIndex: '',
        cityIndex: '',
        countyIndex: '',
        cityList: [], // 城市
        countyList: [], // 区县
        popoverWidth: 150,
        selectText: '', // 选择的数据
        selectId: [],
        checkedSatus: {
          0: '', // 未选中
          1: 'is-indeterminate', // 半选
          2: 'is-checked', // 全选
        },
      })

      const popperOptions = {
        modifiers: [
          {
            name: 'arrowPosition',
            enabled: true,
            phase: 'main',
            fn: ({ state }) => {
              const { modifiersData, placement } = state
              if (['right', 'left'].includes(placement)) return
              modifiersData.arrow.x = 35
            },
            requires: ['arrow'],
          },
        ],
      }

      // 数据初始化
      const initData = (val) => {
        const defaultVal = val.split(',').map((item) => +item)
        setTimeout(() => {
          if (state.selectText) return
          areaList.value.forEach((item) => {
            const { id } = item
            if (defaultVal.includes(id)) {
              item.checked = 2
              item.children = setItemChildrenCheck(item.children, 2)
            }
            if (item?.children?.length) {
              item.children.forEach((v) => {
                const { id: vid } = v
                if (defaultVal.includes(vid)) {
                  v.checked = 2
                  v.children = setItemChildrenCheck(v.children, 2)
                  item.checked = 1
                }
                if (v?.children?.length) {
                  v.children.forEach((vv) => {
                    const { id: vvid } = vv
                    if (defaultVal.includes(vvid)) {
                      vv.checked = 2
                      v.checked = 1
                      item.checked = 1
                    }
                  })
                }
              })
            }
          })
          state.selectText = defaultVal
            .map((item) => areaListObj.value[item].name)
            .join(' | ')
        }, 1500)
      }
      // 设置状态
      const setItemChildrenCheck = (data, checked) => {
        let result = data.map((item) => {
          item.checked = checked
          if (item?.children?.length) {
            item.children = setItemChildrenCheck(item?.children, checked)
          }
          return item
        })
        return result
      }

      // 省份
      const handleProvice = (item, index, type) => {
        if (type) {
          const checked = item.checked ? 0 : 2
          item.checked = checked
          item.children = setItemChildrenCheck(item.children, checked)
        }
        state.provinceIndex = index
        state.cityList = item.children
        state.countyIndex = ''
        state.cityIndex = ''
        state.popoverWidth = 300
        type && handleSelectText()
      }

      // 市
      const handleCity = (item, index, type) => {
        if (type) {
          const checked = item.checked ? 0 : 2
          item.checked = checked
          item.children = setItemChildrenCheck(item.children, checked)
        }
        const cityCheckedList = state.cityList.filter(
          (item) => item.checked === 2
        )
        if (cityCheckedList.length === 0) {
          areaList.value[state.provinceIndex].checked = 0
        } else if (cityCheckedList.length === state.cityList.length) {
          areaList.value[state.provinceIndex].checked = 2
        } else {
          areaList.value[state.provinceIndex].checked = 1
        }
        state.cityIndex = index
        state.countyList = item.children
        state.countyIndex = ''
        state.popoverWidth = 450
        type && handleSelectText()
      }
      // 区
      const handleCounty = (item, index, type) => {
        if (type) {
          const checked = item.checked ? 0 : 2
          item.checked = checked
        }
        const countyCheckedList = state.countyList.filter(
          (item) => item.checked === 2
        )
        if (countyCheckedList.length === 0) {
          state.cityList[state.cityIndex].checked = 0
        } else if (countyCheckedList.length === state.countyList.length) {
          state.cityList[state.cityIndex].checked = 2
        } else {
          state.cityList[state.cityIndex].checked = 1
        }

        const cityCheckedList = state.cityList.filter(
          (item) => item.checked === 2
        )
        if (cityCheckedList.length === 0) {
          const cityHalfCheckedList = state.cityList.filter(
            (item) => item.checked === 1
          )
          if (cityHalfCheckedList.length) {
            areaList.value[state.provinceIndex].checked = 1
          } else {
            areaList.value[state.provinceIndex].checked = 0
          }
        } else if (cityCheckedList.length === state.cityList.length) {
          areaList.value[state.provinceIndex].checked = 2
        } else {
          areaList.value[state.provinceIndex].checked = 1
        }
        state.countyIndex = index
        type && handleSelectText()
      }

      const handleSelectResult = (data = areaList.value) => {
        const len = data.length
        for (let i = 0; i < len; i++) {
          const item = data[i]
          if (item.checked === 2) {
            state.selectId.push(item.id)
          } else if (item.checked === 1) {
            if (item?.children?.length) {
              handleSelectResult(item?.children)
            }
          }
        }
      }
      const handleSelectText = () => {
        state.selectId = []
        handleSelectResult()
        state.selectText = state.selectId
          .map((item) => areaListObj.value[item].name)
          .join(' | ')
        emit('onChange', state.selectId)
      }

      return {
        ...toRefs(state),
        areaList,
        areaListObj,
        popperOptions,
        initData,
        handleProvice,
        handleCity,
        handleCounty,
        setItemChildrenCheck,
        handleSelectResult,
        handleSelectText,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .popper-class-area {
    padding: 0px !important;
  }
  .select-wrapper {
    width: 100%;
    height: 35px;
    border: 1px solid #dcdfe6;
    border-radius: 2px;
    &-active {
      border-color: var(--el-color-primary);
    }
  }
  .area-dropdown-wraper {
    .el-scrollbar {
      border-right: 1px solid #dcdfe6;
      &:last-child {
        border: none;
      }
    }
    .scrollbar-item {
      padding: 0;
      margin: 5px 0;
      width: 150px;
      box-sizing: border-box;
      li {
        list-style: none;
        padding: 0 20px;
        cursor: pointer;
        &:hover {
          background: var(--el-cascader-node-background-hover);
        }
        &.is-active-node {
          color: var(--el-cascader-menu-selected-font-color);
          font-weight: bold;
        }
      }
    }
  }
  .reverse-icon {
    transform: rotate(180deg);
  }
</style>
