<template>
  <el-badge :hidden="!unReadNum" type="danger" :value="unReadNum">
    <vab-icon icon="notification-line" @click="handleShowDrawer" />
  </el-badge>
  <el-drawer
    v-model="showDrawer"
    append-to-body
    custom-class="drawer-notice-class"
    modal-class="drawer-notice-modal-class"
    size="25%"
    title="I am the title"
  >
    <template #header>
      <div>
        <span class="fs16 b">站内信</span>
        <span class="c0 fs14 cp" @click="handleReadAllNotice">全部已读</span>
      </div>
    </template>
    <div class="fv h">
      <div class="p10 bbor fx">
        <el-select
          v-model="read_status"
          class="w120"
          clearable
          filterable
          placeholder="消息状态"
          value-key=""
          @change="handleSelectChange"
        >
          <el-option label="全部" value="" />
          <el-option
            v-for="(key, val) in NOTICE_STATUS"
            :key="key"
            :label="key"
            :value="val"
          />
        </el-select>
        <el-cascader
          class="ml10"
          clearable
          :options="noticeTypeTree"
          placeholder="请选择消息类型"
          :props="props1"
          @change="handleSelectChange($event, 'cascader')"
        />
      </div>
      <div v-infinite-scroll="handleGetNoticeList" class="ex oy mh0">
        <div
          v-for="(item, index) in listData"
          :key="index"
          class="infinite-list-item bbor plr15"
          @click="handleReadNotice(item, index)"
        >
          <div class="fxmj ptb10">
            <span
              class="dl ptb5 plr10 bgtl r100px tags c0"
              :class="[item.read_status ? 'read' : 'tag']"
            >
              {{ item.title }}
            </span>
            <span>{{ item.create_time }}</span>
          </div>
          <div class="pb10 pt5 b c1 pl5 fxmj">
            <div>{{ item.content }}</div>
            <span v-if="!item.read_status" class="c0">标记已读</span>
          </div>
        </div>
        <div
          v-if="pagination.last_page && pagination.page > pagination.last_page"
          class="tc ptb10"
        >
          没有更多了~
        </div>
      </div>
    </div>
  </el-drawer>
</template>

<script>
  import {
    defineComponent,
    getCurrentInstance,
    reactive,
    toRefs,
    onMounted,
    onUnmounted,
    computed,
  } from 'vue'
  import dayjs from 'dayjs'
  import { useStore } from 'vuex'
  import { translateTitle } from '@/utils/i18n'
  import {
    getUnreadTotal,
    getNoticeList,
    readNotice,
    readAllNotice,
  } from '@/api/notice'
  import { NOTICE_STATUS } from '@/config/variable.config'
  export default defineComponent({
    name: 'VabNotice',
    setup() {
      const store = useStore()
      const { proxy } = getCurrentInstance()
      const noticeSubType = computed(() => store.getters['sys/noticeSubType'])
      const noticeTypeTree = computed(() => store.getters['sys/noticeTypeTree'])
      const state = reactive({
        time: `${dayjs().subtract(1, 'month').format('YYYY-MM-DD')} 00:00:00`,
        unReadNum: 0,
        timer: null,
        showDrawer: false,
        msg_type: '',
        msg_sub_type: '',
        read_status: '',
        listData: [],
        pagination: {
          page: 1,
          per_page: 10,
        },
        props1: {
          checkStrictly: true,
          label: 'title',
          value: 'id',
        },
      })

      const getUnreadTotalData = () => {
        const params = {
          time: state.time,
        }
        getUnreadTotal(params).then((res) => {
          console.log('res', res)
          if (state.unReadNum !== res.unread_total) {
            state.pagination.page = 1
            handleGetNoticeList()
          }
          state.unReadNum = res.unread_total
        })
      }

      const handleShowDrawer = () => {
        if (state.showDrawer) {
          state.showDrawer = false
          return
        }
        state.showDrawer = true
      }

      const handleSelectChange = (val, type) => {
        state.pagination.page = 1
        if (type === 'cascader') {
          if (val) {
            const [msg_type, msg_sub_type] = val
            if (msg_sub_type) {
              state.msg_sub_type = msg_sub_type
              state.msg_type = ''
            } else {
              state.msg_sub_type = ''
              state.msg_type = msg_type || ''
            }
          } else {
            state.msg_sub_type = ''
            state.msg_type = ''
          }
        }
        handleGetNoticeList()
      }

      const handleGetNoticeList = () => {
        if (state.pagination.page > state.pagination.last_page) return
        const params = {
          msg_type: state.msg_type,
          msg_sub_type: state.msg_sub_type,
          read_status: state.read_status,
          page: state.pagination.page,
          per_page: state.pagination.per_page,
          create_time_start: state.time,
          sort: 'id',
          order: 'desc',
        }
        getNoticeList(params).then((res) => {
          if (res.current_page === 1) {
            state.listData = res.data || []
          } else {
            state.listData = state.listData.concat(res.data)
          }
          state.pagination = {
            current_page: res.current_page,
            last_page: res.last_page,
            per_page: res.per_page,
            total: res.total,
            page: state.pagination.page + 1,
          }
        })
      }

      const handleReadNotice = (item, index) => {
        if (item.read_status) return
        const params = {
          id: item.id,
        }
        readNotice(params).then((res) => {
          console.log('res', res)
          state.listData[index].read_status = 1
          getUnreadTotalData()
        })
      }

      const handleClearNotice = () => {
        proxy.$baseMessage('清空消息成功', 'success', 'vab-hey-message-success')
      }

      const handleReadAllNotice = () => {
        const params = {
          msg_type: state.msg_type,
          msg_sub_type: state.msg_sub_type,
          time: state.time,
        }
        readAllNotice(params).then((res) => {
          console.log('res', res)
          getUnreadTotalData()
          handleGetNoticeList()
        })
      }

      const handleSetInterval = () => {
        getUnreadTotalData()
        state.timer = setInterval(() => {
          getUnreadTotalData()
        }, 1000 * 60 * 3)
      }

      onMounted(() => {
        handleSetInterval()
      })

      onUnmounted(() => {
        clearInterval(state.timer)
      })
      return {
        ...toRefs(state),
        noticeTypeTree,
        noticeSubType,
        NOTICE_STATUS,
        translateTitle,
        handleClearNotice,
        handleShowDrawer,
        handleGetNoticeList,
        handleReadNotice,
        handleReadAllNotice,
        handleSelectChange,
      }
    },
  })
</script>

<style lang="scss">
  @import '../../../styles/variable.scss';
  .drawer-notice-class {
    display: flex;
    flex-direction: column;
    height: auto !important;
    top: 60px !important;
    .el-drawer__body {
      flex: 1;
      padding: 0;
      min-height: 0;
      .infinite-list-item {
        .tags {
          border: 1px solid $primary-opt;
        }
        .read {
          background-color: #e7eaef;
          color: #fff;
        }

        &:hover {
          cursor: pointer;
          background-color: #e7eaef;
          .tag {
            background-color: $primary;
            // border: 1px solid $primary;
            color: #fff;
          }
          .read {
            border: 1px solid #fff;
          }
        }
      }
    }
  }
  .drawer-notice-modal-class {
    background-color: transparent;
  }
</style>
