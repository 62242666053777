import { getRequest, postRequest } from './api'

const moduleName = 'comment'
export function getCommentList(params = {}) {
  const url = `${moduleName}/index`
  return getRequest(url, { ...params, per_page: 100000000 })
}

export function addComment(params = {}) {
  const url = `${moduleName}/add`
  return postRequest(url, params)
}

export function editComment(params = {}) {
  const url = `${moduleName}/edit`
  return postRequest(url, params)
}

export function getCommentDetail(params = {}) {
  const url = `${moduleName}/detail`
  return getRequest(url, params)
}

export function deleteComment(params = {}) {
  const url = `${moduleName}/delete`
  return postRequest(url, params)
}
