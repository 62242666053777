/**
 * @description router全局配置，如有必要可分文件抽离，其中asyncRoutes只有在intelligence模式下才会用到，pro版只支持remixIcon图标，具体配置请查看vip群文档
 */
import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from 'vue-router'
import Layout from '@/vab/layouts'
import { authentication, isHashRouterMode, publicPath } from '@/config'

export const constantRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login'),
    meta: {
      noKeepAlive: true,
      hidden: true,
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/register'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/callback',
    name: 'Callback',
    component: () => import('@/views/callback'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/403',
    name: 'Page403',
    component: () => import('@/views/403'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/404',
    name: 'Page404',
    component: () => import('@/views/404'),
    meta: {
      hidden: true,
    },
  },
]

export const asyncRoutes = [
  {
    path: '/',
    name: 'Root',
    component: Layout,
    meta: {
      title: '首页',
      icon: 'home-2-line',
      breadcrumbHidden: true,
    },
    children: [
      {
        path: 'index',
        name: 'Index',
        component: () => import('@/views/index'),
        meta: {
          title: '首页',
          icon: 'home-2-line',
          noClosable: true,
        },
      },
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () => import('@/views/index/dashboard'),
        meta: {
          title: '看板',
          icon: 'dashboard-line',
        },
      },
      {
        path: 'workbench',
        name: 'Workbench',
        component: () => import('@/views/index/workbench'),
        meta: {
          title: '工作台',
          icon: 'settings-6-line',
          dot: true,
        },
      },
      {
        path: 'coupon',
        name: 'CouponList',
        component: () => import('@/views/marketing/couponList'),
        meta: {
          noKeepAlive: true,
          title: '卡券管理',
        },
      },
      {
        path: 'couponPackage',
        name: 'CouponPackage',
        component: () => import('@/views/marketing/couponPackage'),
        meta: {
          noKeepAlive: true,
          title: '卡包管理',
        },
      },
      {
        path: 'thirdStore',
        name: 'thirdStore',
        component: () => import('@/views/marketing/thirdStore'),
        meta: {
          noKeepAlive: true,
          title: '美团抖音',
        },
      },
      {
        path: 'rechargeSetList',
        name: 'RechargeSetList',
        component: () => import('@/views/finance/rechargeSetList'),
        meta: {
          title: '充值套餐',
          noKeepAlive: false,
        },
      },
    ],
  },
  // 物料管理
  {
    path: '/material',
    name: 'MaterialManage',
    component: Layout,
    meta: {
      title: '物料',
      icon: 'computer-line',
    },
    children: [
      {
        path: 'materialList',
        name: 'MaterialList',
        component: () => import('@/views/material/materialList'),
        meta: {
          title: '物料列表',
          icon: 'computer-line',
          // noColumn: true,
        },
      },
      {
        path: 'policyList',
        name: 'PricePolicyList',
        component: () => import('@/views/material/policyList'),
        meta: {
          title: '计费策略',
          icon: 'computer-line',
          // noColumn: true,
        },
      },
      {
        path: 'recordList',
        name: 'MaterialRecordList',
        component: () => import('@/views/material/recordList'),
        meta: {
          title: '库存记录',
          icon: 'computer-line',
          // noColumn: true,
        },
      },
      {
        path: 'itemList',
        name: 'MaterialItemList',
        component: () => import('@/views/material/itemList'),
        meta: {
          title: '物品列表',
        },
      },
    ],
  },
  // 用户管理
  {
    path: '/user',
    name: 'UserManage',
    component: Layout,
    meta: {
      title: '用户',
      icon: 'user-settings-line',
    },
    children: [
      {
        path: 'list',
        name: 'UserList',
        component: () => import('@/views/user'),
        meta: {
          title: '用户列表',
        },
      },
      {
        path: 'vip',
        name: 'VipList',
        component: () => import('@/views/user/vipList'),
        meta: {
          title: 'VIP列表',
        },
      },
      {
        path: 'feedbackManagement',
        name: 'FeedbackManagement',
        component: Layout,
        meta: {
          title: '反馈管理',
        },
        children: [
          {
            path: 'typeList',
            name: 'FeedbackTypeList',
            component: () => import('@/views/user/feedback/typeList'),
            meta: {
              title: '反馈类型',
            },
          },
          {
            path: 'feedbackList',
            name: 'FeedbackList',
            component: () => import('@/views/user/feedback/feedbackList'),
            meta: {
              title: '反馈列表',
            },
          },
        ],
      },
      {
        path: 'topAccess',
        name: 'TopAccess',
        component: () => import('@/views/user/topAccess'),
        meta: {
          title: '访问统计',
        },
      },
      {
        path: 'group',
        name: 'GroupList',
        component: () => import('@/views/user/group'),
        meta: {
          title: '用户分组',
        },
      },
    ],
  },
  // 团队管理
  {
    path: '/team',
    name: 'TeamManage',
    component: Layout,
    meta: {
      title: '团队',
      icon: 'user-settings-line',
    },
    children: [
      {
        path: 'accountList',
        name: 'AccountList',
        component: () => import('@/views/user/accountList'),
        meta: {
          title: '成员列表',
        },
      },
      {
        path: 'roleList',
        name: 'RoleList',
        component: () => import('@/views/user/roleList'),
        meta: {
          title: '角色管理',
        },
      },
      {
        path: 'teamList',
        name: 'TeamList',
        component: () => import('@/views/user/teamList'),
        meta: {
          title: '团队管理',
        },
      },
      {
        path: 'memberList',
        name: 'MemberList',
        component: () => import('@/views/user/memberList'),
        meta: {
          title: '团队成员',
          noKeepAlive: true,
        },
      },
    ],
  },
  // 财务管理
  {
    path: '/finance',
    name: 'Finance',
    component: Layout,
    meta: {
      title: '财务',
      icon: 'user-settings-line',
    },
    children: [
      {
        path: 'orderList',
        name: 'FinanceOrderList',
        component: () => import('@/views/finance/orderList'),
        meta: {
          title: '用户订单',
          icon: 'computer-line',
          // noColumn: true,
          noKeepAlive: true,
        },
      },
      {
        path: 'orderDetail',
        name: 'FinanceOrderDetail',
        component: () => import('@/views/finance/orderDetail'),
        meta: {
          noKeepAlive: true,
          title: '订单详情',
          icon: 'computer-line',
          // noColumn: true,
        },
      },
      {
        path: 'billList',
        name: 'FinanceBillList',
        component: () => import('@/views/finance/billList'),
        meta: {
          title: '账单管理',
          icon: 'computer-line',
          // noColumn: true,
        },
      },
      {
        path: 'payTplList',
        name: 'PayTplList',
        component: () => import('@/views/finance/payTplList'),
        meta: {
          title: '推广分账',
          icon: 'computer-line',
          // noColumn: true,
        },
      },
      {
        path: 'merchantList',
        name: 'MerchantList',
        component: () => import('@/views/finance/merchantList'),
        meta: {
          title: '经销商户',
        },
      },
      {
        path: 'profitShareList',
        name: 'ProfitShareList',
        component: () => import('@/views/finance/profitShareList'),
        meta: {
          title: '经销分账',
        },
      },
      {
        path: 'cashApply',
        name: 'CashApplyList',
        component: () => import('@/views/finance/cashApplyList'),
        meta: {
          title: '提现申请',
        },
      },
      {
        path: 'merchantOrder',
        name: 'FinanceMerchantOrderList',
        component: () => import('@/views/finance/merchantOrder'),
        meta: {
          title: '商户订单',
          icon: 'computer-line',
          // noColumn: true,
          noKeepAlive: false,
        },
      },
      {
        path: 'rechargeSetList',
        name: 'RechargeSetList',
        component: () => import('@/views/finance/rechargeSetList'),
        meta: {
          title: '充值套餐',
          noKeepAlive: false,
        },
      },
      {
        path: 'roomLedger',
        name: 'RoomLedgers',
        component: () => import('@/views/finance/roomLedger'),
        meta: {
          title: '共享账本',
          noKeepAlive: false,
        },
      },
    ],
  },
  // 授权管理
  {
    path: '/authorize',
    name: 'Authorize',
    component: Layout,
    meta: {
      title: '授权',
      icon: 'user-settings-line',
    },
    children: [
      {
        path: 'index',
        name: 'AuthorizeIndex',
        component: () => import('@/views/authorize'),
        meta: {
          title: '授权列表',
          icon: 'computer-line',
          // noColumn: true,
        },
      },
      {
        path: 'center',
        name: 'AuthorizeCenter',
        component: () => import('@/views/authorize/center'),
        meta: {
          title: '授权中心',
          icon: 'computer-line',
          // noColumn: true,
        },
      },
    ],
  },
  // 广告管理
  {
    path: '/ad',
    name: 'adManage',
    component: Layout,
    meta: {
      title: '广告',
      icon: 'user-settings-line',
    },
    children: [
      {
        path: 'mediaList',
        name: 'MediaList',
        component: () => import('@/views/advertising/media/index'),
        meta: {
          title: '素材管理',
          icon: 'computer-line',
          // noColumn: true,
        },
      },
      {
        path: 'adTplList',
        name: 'AdTplList',
        component: () => import('@/views/advertising/adTpl/index'),
        meta: {
          title: '模板管理',
          icon: 'computer-line',
          // noColumn: true,
        },
      },
      {
        path: 'adTplDetail',
        name: 'AdTplDetail',
        component: () => import('@/views/advertising/adTpl/detail'),
        meta: {
          hidden: true,
          noKeepAlive: true,
          title: '模板详情',
          icon: 'computer-line',
          // noColumn: true,
        },
      },
      {
        path: 'adProgramList',
        name: 'AdProgramList',
        component: () => import('@/views/advertising/adProgram/index'),
        meta: {
          title: '节目管理',
          icon: 'computer-line',
          // noColumn: true,
        },
      },
      {
        path: 'adProgramDetail',
        name: 'AdProgramDetail',
        component: () => import('@/views/advertising/adProgram/detail'),
        meta: {
          hidden: true,
          noKeepAlive: true,
          title: '节目详情',
          icon: 'computer-line',
          // noColumn: true,
        },
      },
      {
        path: 'adGuideList',
        name: 'AdGuideList',
        component: () => import('@/views/advertising/adGuide/index'),
        meta: {
          title: '播单管理',
          icon: 'computer-line',
          // noColumn: true,
        },
      },
      {
        path: 'adGuideDetail',
        name: 'AdGuideDetail',
        component: () => import('@/views/advertising/adGuide/detail'),
        meta: {
          noKeepAlive: true,
          title: '播单详情',
          icon: 'computer-line',
          hidden: true,
          // noColumn: true,
        },
      },
      {
        path: 'taskList',
        name: 'TaskList',
        component: () => import('@/views/authorize/taskList'),
        meta: {
          title: '任务管理',
          icon: 'computer-line',
          // noColumn: true,
        },
      },
      {
        path: 'taskDetail',
        name: 'TaskDetail',
        component: () => import('@/views/authorize/taskDetail'),
        meta: {
          hidden: true,
          noKeepAlive: true,
          title: '任务详情',
          icon: 'computer-line',
          // noColumn: true,
        },
      },
      {
        path: 'taskLogList',
        name: 'TaskLogList',
        component: () => import('@/views/authorize/taskLogList'),
        meta: {
          title: '任务记录',
        },
      },
      {
        path: 'authorizeIndex',
        name: 'AuthorizeIndex',
        component: () => import('@/views/authorize'),
        meta: {
          title: '授权列表',
          icon: 'computer-line',
          // noColumn: true,
        },
      },
      {
        path: 'wxmp',
        name: 'Wxmp',
        component: () => import('@/views/authorize/wxmp'),
        meta: {
          title: '小程序',
          icon: 'computer-line',
          // noColumn: true,
        },
      },
      {
        path: 'authorizeCenter',
        name: 'AuthorizeCenter',
        component: () => import('@/views/authorize/center'),
        meta: {
          title: '授权中心',
          icon: 'computer-line',
          // noColumn: true,
        },
      },
    ],
  },

  // 配置管理
  {
    path: '/setting',
    name: 'PersonnelManagement',
    component: Layout,
    hidden: true,
    meta: {
      title: '配置',
      icon: 'user-settings-line',
      // guard: ['Admin'],
    },
    children: [
      // 产品管理
      {
        path: 'product',
        name: 'ProductManage',
        component: Layout,
        meta: {
          title: '产品',
          icon: 'function-line',
        },
        children: [
          {
            path: 'list',
            name: 'ProductList',
            component: () => import('@/views/product'),
            meta: {
              title: '产品列表',
              icon: 'computer-line',
            },
          },
          {
            path: 'detail',
            name: 'ProductDetail',
            component: () => import('@/views/product/detail'),
            meta: {
              hidden: true,
              noKeepAlive: true,
              title: '列表详情',
              icon: 'computer-line',
            },
          },
          {
            path: 'myapp',
            name: 'InstallList',
            component: () => import('@/views/product/appInstallList'),
            meta: {
              title: '已装产品',
              icon: 'computer-line',
            },
          },
          {
            path: 'installDetail',
            name: 'InstallListDetail',
            component: () => import('@/views/product/appInstallDetail'),
            meta: {
              noKeepAlive: true,
              title: '已装产品设置',
              icon: 'computer-line',
            },
          },
          {
            path: 'install',
            name: 'MyAppList',
            component: () => import('@/views/product/my'),
            meta: {
              title: '我的产品',
              icon: 'computer-line',
              noKeepAlive: true,
            },
          },
          {
            path: 'model',
            name: 'ProductModelList',
            component: () => import('@/views/product/model'),
            meta: {
              hidden: true,
              noKeepAlive: true,
              title: '型号列表',
              icon: 'computer-line',
            },
          },
          {
            path: 'aisle',
            name: 'ModelAisleList',
            component: () => import('@/views/product/aisle'),
            meta: {
              hidden: true,
              noKeepAlive: true,
              title: '货道列表',
              icon: 'computer-line',
            },
          },
        ],
      },
      {
        path: 'personalCenter',
        name: 'PersonalCenter',
        component: () => import('@/views/setting/personalCenter'),
        meta: {
          title: '个人中心',
          icon: 'map-pin-user-line',
        },
      },
      // 设备管理
      {
        path: 'device',
        name: 'DeviceManage',
        component: Layout,
        meta: {
          title: '设备',
          icon: 'computer-line',
        },
        children: [
          {
            path: 'list',
            name: 'DeviceList',
            component: () => import('@/views/device'),
            meta: {
              title: '设备列表',
              noKeepAlive: false,
              icon: 'computer-line',
              // noColumn: true,
            },
          },
          {
            path: 'detail',
            name: 'DeviceDetail',
            component: () => import('@/views/device/detail'),
            meta: {
              hidden: true,
              noKeepAlive: true,
              title: '设备详情',
              activeMenu: '/device/list',
              icon: 'computer-line',
            },
          },
          // {
          //   path: 'placeList',
          //   name: 'PlaceList',
          //   component: () => import('@/views/device/placeList'),
          //   meta: {
          //     hidden: true,
          //     title: '地点管理',
          //     icon: 'computer-line',
          //   },
          // },
          {
            path: 'accountList',
            name: 'AccountList',
            component: () => import('@/views/device/accountList'),
            meta: {
              hidden: true,
              title: '设备账号',
            },
          },
        ],
      },
      {
        path: 'userManagement',
        name: 'UserManagement',
        component: () => import('@/views/setting/userManagement'),
        meta: {
          title: '用户管理',
          icon: 'user-3-line',
        },
      },
      {
        path: 'roleManagement',
        name: 'RoleManagement',
        component: () => import('@/views/setting/roleManagement'),
        meta: {
          title: '角色管理',
          icon: 'admin-line',
        },
      },
      {
        path: 'operationManagement',
        name: 'OperationManagement',
        component: () => import('@/views/setting/operationManagement'),
        meta: {
          title: '操作管理',
          icon: 'list-settings-line',
        },
      },
      {
        path: 'menuManagement',
        name: 'MenuManagement',
        component: () => import('@/views/setting/menuManagement/index'),
        meta: {
          title: '菜单管理',
          icon: 'menu-2-fill',
        },
      },
      {
        path: 'sceneList',
        name: 'SceneList',
        component: () => import('@/views/device/sceneList'),
        meta: {
          hidden: true,
          title: '场景管理',
          icon: 'computer-line',
        },
      },
      {
        path: 'adminLog',
        name: 'AdminLogList',
        component: () => import('@/views/setting/log/adminLogList'),
        meta: {
          title: '操作日志',
        },
      },
      {
        path: 'principalList',
        name: 'PrincipalList',
        component: () => import('@/views/setting/principal/index'),
        meta: {
          title: '主体管理',
        },
      },
      {
        path: 'principalInfo',
        name: 'PrincipalInfo',
        component: () => import('@/views/setting/principal/detail'),
        meta: {
          title: '主体信息',
        },
      },
      {
        path: 'dataGroup',
        name: 'DataGroup',
        component: () => import('@/views/setting/dataGroupManagement/index'),
        meta: {
          title: '数据配置',
        },
      },
      {
        path: 'screenUser',
        name: 'ScreenUser',
        component: () => import('@/views/setting/screenUser'),
        meta: {
          title: '大屏用户',
        },
      },
      {
        path: 'pageManage',
        name: 'PageManagement',
        component: () => import('@/views/setting/pageManagement/index'),
        meta: {
          title: '页面功能',
        },
      },
      {
        path: 'questionAnswer',
        name: 'QuestionAnswerManagement',
        component: () =>
          import('@/views/setting/questionAnswerManagement/index'),
        meta: {
          title: '常见问题',
        },
      },
      {
        path: 'labelManagement',
        name: 'LabelManagement',
        component: () => import('@/views/setting/labelManagement/index'),
        meta: {
          title: '标签管理',
        },
      },
      {
        path: 'projectList',
        name: 'ProjectList',
        component: () => import('@/views/setting/project/index'),
        meta: {
          title: '项目管理',
        },
      },
    ],
  },

  // 营销管理
  {
    path: '/marketing',
    name: 'MarketingManagement',
    component: Layout,
    hidden: true,
    meta: {
      title: '营销',
      icon: 'user-settings-line',
    },
    children: [
      {
        path: 'coupon',
        name: 'CouponList',
        component: () => import('@/views/marketing/couponList'),
        meta: {
          noKeepAlive: true,
          title: '卡券管理',
        },
      },
      {
        path: 'couponPackage',
        name: 'CouponPackage',
        component: () => import('@/views/marketing/couponPackage'),
        meta: {
          noKeepAlive: true,
          title: '卡包管理',
        },
      },
      {
        path: 'districtList',
        name: 'DistrictList',
        component: () => import('@/views/marketing/districtList'),
        meta: {
          noKeepAlive: true,
          title: '区域管理',
        },
      },
      {
        path: 'seatList',
        name: 'SeatList',
        component: () => import('@/views/marketing/seatList'),
        meta: {
          noKeepAlive: true,
          title: '座位管理',
        },
      },
      {
        path: 'storeList',
        name: 'StoreList',
        component: () => import('@/views/marketing/storeList'),
        meta: {
          noKeepAlive: true,
          title: '合作店铺',
        },
      },
      {
        path: 'roomList',
        name: 'RoomList',
        component: () => import('@/views/marketing/roomList'),
        meta: {
          title: '房间管理',
        },
      },
      {
        path: 'virtualRoomList',
        name: 'VirtualRoomList',
        component: () => import('@/views/marketing/virtualRoomList'),
        meta: {
          title: '虚拟房间',
        },
      },
      {
        path: 'packageList',
        name: 'RoomPackage',
        component: () => import('@/views/marketing/roomPackage'),
        meta: {
          hidden: true,
          noKeepAlive: true,
          title: '套餐管理',
        },
      },
      {
        path: 'roomDetail',
        name: 'RoomDetail',
        component: () => import('@/views/marketing/roomDetail'),
        meta: {
          hidden: true,
          noKeepAlive: true,
          title: '房间详情',
        },
      },
      {
        path: 'virtualRoomDetail',
        name: 'VirtualRoomDetail',
        component: () => import('@/views/marketing/virtualRoomDetail'),
        meta: {
          hidden: true,
          noKeepAlive: true,
          title: '虚拟详情',
        },
      },
      {
        path: 'meituanMerchantList',
        name: 'MeituanMerchantList',
        component: () => import('@/views/marketing/meituanMerchantList'),
        meta: {
          noKeepAlive: true,
          title: '美团店铺',
        },
      },
      {
        path: 'thirdStore',
        name: 'thirdStore',
        component: () => import('@/views/marketing/thirdStore'),
        meta: {
          noKeepAlive: true,
          title: '三方店铺',
        },
      },
      {
        path: 'storeProductList',
        name: 'StoreProductList',
        component: () => import('@/views/marketing/storeProductList'),
        meta: {
          noKeepAlive: true,
          title: '店铺商品',
        },
      },
      {
        path: 'channelList',
        name: 'ChannelList',
        component: () => import('@/views/marketing/channelList'),
        meta: {
          noKeepAlive: true,
          title: '渠道管理',
        },
      },
      {
        path: 'roomManager',
        name: 'RoomManager',
        component: () => import('@/views/marketing/roomManager'),
        meta: {
          title: '房间监管',
        },
      },
    ],
  },
  {
    path: '/course',
    name: 'CourseModel',
    component: Layout,
    hidden: true,
    meta: {
      title: '课程',
      icon: 'user-settings-line',
    },
    children: [
      {
        path: 'scheduling',
        name: 'CourseScheduling',
        component: () => import('@/views/course/courseScheduling'),
        meta: {
          noKeepAlive: true,
          title: '排课管理',
        },
      },
      {
        path: 'courseGroup',
        name: 'CourseGroup',
        component: () => import('@/views/course/courseCategory'),
        meta: {
          noKeepAlive: true,
          title: '课程分类',
        },
      },
      {
        path: 'courseList',
        name: 'CourseList',
        component: () => import('@/views/course/courseList'),
        meta: {
          noKeepAlive: true,
          title: '课程列表',
        },
      },
      {
        path: 'teacher',
        name: 'TeacherList',
        component: () => import('@/views/course/TeacherList'),
        meta: {
          title: '教练管理',
        },
      },
    ],
  },
  {
    path: '/outdoor',
    name: 'OutdoorModel',
    component: Layout,
    hidden: true,
    meta: {
      title: '户外',
    },
    children: [
      {
        path: 'activityList',
        name: 'OutdoorActivityList',
        component: () => import('@/views/outdoor/activityList'),
        meta: {
          noKeepAlive: true,
          title: '活动列表',
        },
      },
      {
        path: 'typeList',
        name: 'OutdoorActivityTypeList',
        component: () => import('@/views/outdoor/activityTypeList'),
        meta: {
          noKeepAlive: true,
          title: '活动分类',
        },
      },
      {
        path: 'clubList',
        name: 'OutdoorClubList',
        component: () => import('@/views/outdoor/clubList'),
        meta: {
          noKeepAlive: true,
          title: '俱乐部',
        },
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
    name: 'Page404',
    meta: {
      hidden: true,
    },
  },
]

const router = createRouter({
  history: isHashRouterMode
    ? createWebHashHistory(publicPath)
    : createWebHistory(publicPath),
  routes: constantRoutes,
})

function fatteningRoutes(routes) {
  return routes.flatMap((route) => {
    return route.children ? fatteningRoutes(route.children) : route
  })
}

function addRouter(routes) {
  routes.forEach((route) => {
    if (!router.hasRoute(route.name)) router.addRoute(route)
    if (route.children) addRouter(route.children)
  })
}

export function resetRouter(routes = constantRoutes) {
  routes.map((route) => {
    if (route.children) {
      route.children = fatteningRoutes(route.children)
    }
  })
  router.getRoutes().forEach((route) => {
    const routeName = route.name
    router.hasRoute(routeName) && router.removeRoute(routeName)
  })
  addRouter(routes)
}

export function setupRouter(app) {
  if (authentication === 'intelligence') addRouter(asyncRoutes)
  app.use(router)
  return router
}

export default router
